@import "../global-variables.scss";
$selectedColor: #f2ac3d;
$primaryColor: #ffc997;
$backgroundColor: #bf2722;

.main-content.tb-red-orange {
  background-color: $backgroundColor;
  input[type="text"] {
    &::placeholder {
      color: $primaryColor;
      opacity: 1;
    }
    &::-ms-input-placeholder {
      color: $primaryColor;
      opacity: 1;
    }
    &::-ms-input-placeholder {
      color: $primaryColor;
      opacity: 1;
    }
  }

  input[type="radio"]:checked {
    background: $selectedColor;
  }
  input[type="checkbox"]:checked {
    border-color: $selectedColor;
  }
  .background-site-info {
    background-color: $backgroundColor;
  }
  .queue-title-underline {
    background-color: $backgroundColor;
  }
  .queue-bottom-btn-wrapper {
    background-color: $backgroundColor;
  }
  .queue-table-size-wrapper {
    .number-btn-wrapper {
      .table-size-btn {
        background-color: #ffc997;
        &.selected {
          background-color: $selectedColor;
        }
      }
    }
  }
  .bottom-btn-wrapper {
    background-color: $backgroundColor;
    .queue-button {
      background-color: #ffc997;
      color: #000000;
    }
  }
  .calendar .header {
    background-color: #ffc997;
    color: #000000;
    svg {
      path {
        stroke: #000000;
      }
    }
  }
  .calendar .calendar-content tbody td .date-wrapper {
    color: #ffffff;
    &.disable {
      color: #3c3844;
    }
    &.selected {
      background-color: $selectedColor;
      color: #000000;
    }
  }
  .queue-preview-wrapper .queue-preview-content .preview-text {
    color: $selectedColor;
  }
  .queue-request-ticket-success-wrapper .sent {
    color: $primaryColor;
  }
  .queue-bottom-btn-wrapper {
    .queue-button {
      background-color: #ffc997;
      color: #000000;
      &.disable {
        background: #7b7b7b;
        cursor: initial;
      }
    }
  }
  .tnc-title {
    text-decoration: underline;
  }
}

@media only screen and (min-width: $desktopMinWidth) {
  .main-content.tb-red-orange {
    .calendar {
      background-color: #a82d23;
    }
  }
}
