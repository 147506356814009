@import "../global-variables.scss";

.main-content.jockey-club {
  .site-detail-wrapper {
    // height: 100%;
    // padding-bottom: 0px;
    margin-bottom: 0px;
    .tag-sequence-list-wrapper {
      .title {
        text-align: center;
        color: #ffe600;
      }
    }
  }
  .queue-bottom-btn-wrapper {
    .queue-button {
      font-weight: bold;
    }
  }
}

@media only screen and (min-width: $desktopMinWidth) {
  .main-content.jockey-club {
    .site-detail-wrapper {
      .tag-sequence-list-wrapper {
        .title {
          margin-left: 0px;
        }
      }
    }
    .queue-bottom-btn-wrapper {
      .queue-button {
        font-size: 20px;
      }
    }
  }
}
