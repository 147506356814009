@import './global-variables.scss';

.main-content {
  .queue-wrapper {
    width: 100%;
    height: calc(100% - $queueContentBottomPadding);
    padding-bottom: $queueContentBottomPadding;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-direction: column;
    flex: 1;

    .queue-wrapper-content {
      flex: 1;
      justify-content: flex-start;
    }

    .flex-wrapper {
      flex: 1;
      justify-content: flex-start;
    }

  }
  .queue-single-button-wrapper {
    @include display-flex(center, center);
    width: 100%;
    height: 100%;
    min-height: inherit;
    .single-button {
      @include display-flex(center, center);
      width: fit-content;
      height: 35px;
      padding: 0px 44px;
      margin: 0px auto;
      border-radius: 5px;
      background-color: #ffffff;
      color: #000000;
      cursor: pointer;
      &.disable {
        background: #7b7b7b;
        cursor: initial;
      }
    }
  }
}

@media only screen and (min-width: $desktopMinWidth)  {
  .main-content {
    .queue-wrapper {
      height: calc(100% - $desktopQueueContentBottomPadding);
      padding-bottom: 0px;
      margin-bottom: $desktopQueueContentBottomPadding;
      &:not(.ticket-wrapper) {
        &>.gulu-logo-wrapper {
          display: none;
        }
      }
      .queue-wrapper-content {
        @include display-flex(flex-start, flex-start, column);
        max-width: 660px;
        width: 100%;
        margin: 0px auto;
        .queue-title {
          @include display-flex(center, center);
        }
      }
    }
  }
}