@import "./global-variables.scss";

.site-list-wrapper {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  .queue-title {
    font-weight: 400;
    font-size: 14px;
  }
  .sub-folder-list {
    width: calc(100% - 36px);
    padding: 0px 18px;
    .sub-folder {
      width: 100%;
      margin-bottom: 30px;
      font-weight: 700;
      .folder-name {
        margin-bottom: 14px;
        font-size: 24px;
      }
      .site-list {
        .site {
          @include display-flex(flex-start, flex-start);
          width: calc(100% - 48px);
          padding: 24px;
          margin-bottom: 20px;
          border-radius: 5px;
          font-size: 20px;
          color: #000000;
          cursor: pointer;
          &.A {
            background-color: $tableTypeABackgroundColor;
          }
          &.B {
            background-color: $tableTypeBBackgroundColor;
          }
          &.C {
            background-color: $tableTypeCBackgroundColor;
          }
          &.D {
            background-color: $tableTypeDBackgroundColor;
          }
          &.E {
            background-color: $tableTypeEBackgroundColor;
          }
          &.F {
            background-color: $tableTypeFBackgroundColor;
          }
          .site-logo {
            width: 48px;
            height: 48px;
            margin-right: 5px;
            flex-shrink: 0;
          }
          .site-info {
            flex-grow: 1;
            .name-wrapper {
              @include display-flex(flex-start, flex-start);
              .name {
                flex-grow: 1;
                font-size: 26px;
              }
              .image {
                flex-shrink: 0;
                background-color: #ffffff;
              }
            }
            .quota {
              font-size: 20px;
              color: #717171;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: $desktopMinWidth) {
  .site-list-wrapper {
    .site-wrapper {
      margin-bottom: 50px;
    }
    & >.site-list-head> .gulu-logo-wrapper {
      display: none;
    }
    .banner-list {
      max-width: 725px;
      margin: 0px auto;
    }
    .queue-title {
      margin-left: 18px;
      font-weight: 500;
      font-size: 36px;
    }
    .sub-folder-list {
      .sub-folder {
        .folder-name {
          margin-bottom: 14px;
          font-size: 36px;
        }
        .site-list {
          @include display-flex(flex-start, flex-start);
          flex-wrap: wrap;
          .site {
            width: calc(50% - 88px);
            font-size: 34px;
            &:nth-child(odd) {
              margin: 0px 20px 40px 0px;
            }
            &:nth-child(even) {
              margin: 0px 0px 40px 20px;
            }
            &:only-child {
              margin: 0px 0px 40px 0px;
            }
            .site-logo {
              width: 82px;
              height: 82px;
              margin-right: 10px;
            }
            .site-info {
              .name-wrapper {
                .name {
                  font-size: 44px;
                }

                .image {
                  width: 39px;
                  height: 58px;
                }
              }
              .quota {
                font-size: 34px;
              }
            }
          }
        }
      }
    }
  }
}
