@import './global-variables.scss';

.reservation-edit-dialog {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 100;
  position: fixed;
  left: 0px;
  top: 0px;
  .dialog {
    background: #000000;
    border-radius: 10px;
    border: 1px solid #ffffff;
    width: 86%;
    min-height: 50%;
    max-height: 80%;
    padding: 10px 0px;
    min-width: 300px;
    max-width: 800px;
    // display: flex;
    // flex-direction: column;
    overflow-y: auto;
    .queue-title {
      padding: 0px 9px;
      &:first-child {
        @include display-flex(center, center);
        font-size: 18px;
      }
    }
    .select-time-wrapper {
      width: calc(90% - 2px);
      margin-bottom: 20px;
    }
    .dialog-buttons {
      @include display-flex(space-evenly, center);
      .dialog-button {
        width: 40%;
        height: 35px;
        margin: 0px auto;
        border-radius: 5px;
        background-color: #ffffff;
        color: #000000;
        font-weight: bold;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        cursor: pointer;
        &.disable {
          background: #7b7b7b;
          cursor: initial;
        }
      }
    }
  }
}