@import "./global-variables.scss";

.counter-item {
  width: calc(100% - 22px);
  padding: 10px;
  margin: 0px auto 10px auto;
  border: 1px solid transparent;
  border-radius: 5px;
  background-color: #212121;
  @include display-flex(center, center, column);
  &.blink {
    animation: blink-animation 1s 1s linear 4;
    -webkit-animation: blink-animation 1s 1s linear 4;
    .ticket-number {
      .text {
        .tag-label {
          animation: blink-animation-text 1s 1s linear 4;
          -webkit-animation: blink-animation-text 1s 1s linear 4;
        }
      }
    }
    .time {
      .time-label {
        animation: blink-animation-text 1s 1s linear 4;
        -webkit-animation: blink-animation-text 1s 1s linear 4;
      }
    }
  }
  .ticket-number {
    @include display-flex(center, center);
    width: 100%;
    font-weight: bold;
    font-size: 28px;
    line-height: 28px;
    position: relative;
    .image path {
      background-color: #000000;
    }
    .text {
      @include display-flex(center, center);
      .sound-icon {
        width: 27px;
        height: 20px;
        margin-right: 10px;
      }
      .counter-icon {
        width: 6px;
        height: 10px;
        margin: 0px 8px;
      }
      .counter-name {
        @include display-flex(center, center);
        // width: 35px;
        height: 35px;
        padding: 0px 10px;
        border-radius: 50px;
        font-size: 28px;
        background-color: #ffffff;
        color: #000000;
        &.long {
          font-size: 16px;
        }
      }
    }
  }
  .zig-zag {
    @include zig-zag-line(#ffffff, #212121);
  }
  .time {
    font-family: Wallpoet;
    font-weight: normal;
    font-size: 28px;
  }
}

@media only screen and (min-width: $desktopMinWidth) {
  .counter {
    width: calc(100% - 20px);
    padding: 10px;
    margin: 0px auto 30px auto;
    // .table-type-label {
    //   font-size: 27px;
    // }
    .ticket-number {
      font-size: 42px;
      line-height: 42px;
      .text {
        .sound-icon {
          width: 50px;
          height: 37px;
          margin-right: 5px;
        }
      }
    }
    .time {
      font-size: 38px;
    }
  }
}

@keyframes blink-animation {
  to {
    border: 1px solid #FFFFFF;
  }
}
@-webkit-keyframes blink-animation {
  to {
    border: 1px solid #FFFFFF;
  }
}

@keyframes blink-animation-text {
  to {
    opacity: 0;
  }
}
@-webkit-keyframes blink-animation-text {
  to {
    opacity: 0;
  }
}