@import "./global-variables.scss";

$siteHeight: 48px;
$siteHeightDesktop: 90px;

.site-wrapper {
  @include display-flex(flex-start, center);
  width: 100%;
  .site {
    @include display-flex(flex-start, center);
    // min-height: $siteHeight;
    padding: 8px 16px;
    flex-grow: 1;
    &.title-only {
      @include display-flex(flex-start, center);
    }
    .site-image {
      width: $siteHeight;
      height: $siteHeight;
      background: #ffffff;
      border-radius: 5px;
      border: 1px solid white;
      box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
      flex-shrink: 0;
      img {
        max-width: 100%;
        max-height: 100%;
        margin: 0px auto;
        border-radius: inherit;
      }
    }
    .info {
      margin: 0 10px;
      flex-grow: 1;
      .title {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        color: $textColor;
      }
      .address {
        @include display-flex(normal, center);
        margin: 3px 0;
        .location-icon {
          flex-shrink: 0;
          width: 16px;
          height: 18.5px;
          margin: 0 5px 0 0;
        }
        .text {
          text-align: left;
          font-weight: 500;
          font-size: 14px;
          color: $textColor;
        }
      }
    }
  }
  .site-title {
    display: none;
  }
  .gulu-logo-wrapper {
    // display: none;
  }
}

@media only screen and (min-width: $desktopMinWidth) {
  .site-wrapper {
    .site {
      // min-height: $siteHeightDesktop;
      .site-image {
        width: $siteHeightDesktop;
        height: $siteHeightDesktop;
      }
      .info {
        .title {
          font-size: 32px;
        }
        .address {
          @include display-flex(normal, center);
          margin: 3px 0;
          .location-icon {
            flex-shrink: 0;
            width: 30.7px;
            height: 35.5px;
            margin: 0 5px 0 0;
          }
          .text {
            font-size: 28px;
          }
        }
      }
    }
    .site-title {
      display: block;
      font-size: 36px;
      font-weight: 700;
      flex-grow: 1;
    }
    .gulu-logo-wrapper {
      display: flex;
      flex-direction: row-reverse;
      font-size: 18px;
      padding: 8px 16px 8px 0px;
      .gulu-logo {
        .image {
          width: 80px;
          height: 80px;
        }
        .image-bottom {
          margin-top: -80px;
        }
      }
    }
  }
}
