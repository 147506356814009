@import "./global-variables.scss";

.queue-wrapper-content {
  &.queue-request-ticket-success-wrapper {
    @include display-flex(center, center, column);
    padding: 0px 10px;
    margin-top: 30px;
    .success {
      font-size: 24px;
    }
    .sent {
      text-align: center;
      font-size: 16px;
      color: #808080;
      margin-top: 9px;
      .email {
        color: #ffffff;
        font-weight: bold;
      }
      .reminder {
        margin-top: 10px;
      }
    }
  }
}

@media only screen and (min-width: $desktopMinWidth) {
  .main-content {
    .queue-wrapper-content {
      &.queue-request-ticket-success-wrapper {
        @include display-flex(center, center, column);
        .success {
          font-size: 36px;
          margin: 0px auto;
        }
        .sent {
          font-size: 28px;
        }
      }
    }
  }
}
