@import "../global-variables.scss";

.leave-home-safe-counter {
  --tableTypeAColor: #830233;
  --tableTypeBColor: #32590D;
  --tableTypeCColor: #04527C;
  --tableTypeDColor: #654610;
  --tableTypeEColor: #682786;
  --tableTypeFColor: #585219;

  --tableTypeADarkColor: #F4E0E8;
  --tableTypeBDarkColor: #E5F3D6;
  --tableTypeCDarkColor: #DAF1FC;
  --tableTypeDDarkColor: #FFF1D8;
  --tableTypeEDarkColor: #F3DAFF;
  --tableTypeFDarkColor: #FFFDBE;

  --tableTypeAColorOnWhiteBackground: #830233;
  --tableTypeBColorOnWhiteBackground: #32590D;
  --tableTypeCColorOnWhiteBackground: #04527C;
  --tableTypeDColorOnWhiteBackground: #654610;
  --tableTypeEColorOnWhiteBackground: #682786;
  --tableTypeFColorOnWhiteBackground: #585219;

  --tableTypeABackgroundColor: #F4E0E8;
  --tableTypeBBackgroundColor: #E5F3D6;
  --tableTypeCBackgroundColor: #DAF1FC;
  --tableTypeDBackgroundColor: #FFF1D8;
  --tableTypeEBackgroundColor: #F3DAFF;
  --tableTypeFBackgroundColor: #FFFDBE;

  --textColor: #000000;
  --titleColorByTheme: #51AE8A;
  --backgroundColor: #51AE8A;
  --themeBorderColor: #51AE8A;
}

.main-content.leave-home-safe-counter {

  padding: 0px 0px 0px 0px !important;
  height: 0px;

  .lang-wrapper {
    font-weight: 700;
    font-size: 14px;
  }

  .site-list-wrapper {

    display: flex;
    flex-direction: column;

    .site-list-head{
      padding: 18px 18px 0px 18px;
    }

    .site-list-body {
      flex: 1;
      background-color: #ffffff;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      margin-top: 30px;

      .queue-title{
        color: #000000;
      }

    }

    .sub-folder-list {

      :nth-child(odd){
        .site-list > .site{
            color: #000000;
            background-color: #B5DED3;
            border-radius: 26px;
          }
      }

      :nth-child(even){
        .site-list > .site{
          color: #FFFFFF;
          background-color: #51AE8A;
          border-radius: 26px;

          .site-info .quota{
            color: #FFFFFF;
          }
      }

    }

      .folder-name{
        color: #000000;
        font-weight: 700;
      }
    }

    .site-wrapper{
      display: flex;
      flex-direction: column-reverse;

      .dropdown-arrow {
        color: #000000;
      }

      .title-wrapper {
        margin: 0 auto 30px;
        flex-direction: column;
      }

      .site-date {
        color: #F9DB56;
      }

      .site-title {
        font-size: 2rem;
        display: block;
      }

    }

    .gulu-logo-wrapper {

      align-self: flex-end;

      .lang-wrapper {
        align-self: center;
      }
    }

  }


  .site-detail-wrapper {
    height: 100%;
    padding-bottom: 0px; 
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;

    .navigation-list .navigation-button{
      border: 0px;
      font-size: 30px;
    }

    .current-date {
      color: #000000;
    }

    .tag-sequence-list-wrapper {
      .title {
        text-align: center;
        color: #A99800;
        margin-bottom: 30px;
      }
    }

    .background-site-info  .site-wrapper{
      background-color: #51AE8A;
      display: flex;
      flex-direction: column-reverse;
      .gulu-logo-wrapper {
        align-self: flex-end;
        .lang-wrapper {
          align-self: center;
        }
      }

      .info .title{
        color: #FFFFFF;
        font-size: 34.0622px;
      }

    }

    .background-site-info-body {
      background-color: #FFFFFF;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      margin-top: 30px;
      padding: 15px 18px 10px;
      flex: 1;

      .drop-down-title {
        color: #000000;
      }

      .drop-down-content {
        color: #000000;
      }

      
    }

    .background-site-info{
      background-color: #FFFFFF;
    }

    .background-site-info-head {
      background-color: #51AE8A;
    }

    .site .info .address .text{
      font-size: 21.5054px;
      color: #F9DB56;
    }

    .reject-reason {
      color: #51AE8A;
    }

    .tag-sequence .table-type-label {
      color: #000000;
    }

    .tag-sequence{
      border-radius: 5px;
      
      .ticket {
        font-weight: 700;
      }
    }

    .counter-item {
      background-color: #51AE8A;
      &.blink {
        // animation: blink-animation 1s 1s linear 4;
        // -webkit-animation: blink-animation 1s 1s linear 4;
        .ticket-number {
          .text {
            .tag-label {
            animation: leave-home-safe-blink-animation-text 1s 1s linear 4;
            -webkit-animation: leave-home-safe-blink-animation-text 1s 1s linear 4;
            }
          }
        }
        .time {
          animation: leave-home-safe-blink-animation-text-time 1s 1s linear 4;
          -webkit-animation: leave-home-safe-blink-animation-text-time 1s 1s linear 4;
        }
      }
      .text {
        .counter-name {
          background-color: #ffffff;
          color: #51AE8A;
        }
      }
      .zig-zag {
        @include zig-zag-line(#ffffff, #51AE8A);
      }
    }

  }
  .queue-bottom-btn-wrapper {
    .queue-button {
      font-weight: bold;
    }
  }

  .ticket-box {

    .ticket-content{
      background-color: #FFFFFF;
    }

    .zig-zag {
      --tableTypeADarkColor: #FFFFFF;
      --tableTypeBDarkColor: #FFFFFF;
      --tableTypeCDarkColor: #FFFFFF;
      --tableTypeDDarkColor: #FFFFFF;
      --tableTypeEDarkColor: #FFFFFF;
      --tableTypeFDarkColor: #FFFFFF;
    
      --tableTypeABackgroundColor: #FFFFFF;
      --tableTypeBBackgroundColor: #FFFFFF;
      --tableTypeCBackgroundColor: #FFFFFF;
      --tableTypeDBackgroundColor: #FFFFFF;
      --tableTypeEBackgroundColor: #FFFFFF;
      --tableTypeFBackgroundColor: #FFFFFF;
    }
  }

  .ticket-wrapper {
    .title {
      margin: 0;
    }

    .tag-sequence-list .tag-sequence{
      background-color: #FFFFFF;
      
      .table-type-label{
        color: #000000;
      }

      --tableTypeADarkColor: #FFFFFF;
      --tableTypeBDarkColor: #FFFFFF;
      --tableTypeCDarkColor: #FFFFFF;
      --tableTypeDDarkColor: #FFFFFF;
      --tableTypeEDarkColor: #FFFFFF;
      --tableTypeFDarkColor: #FFFFFF;
    
      --tableTypeABackgroundColor: #FFFFFF;
      --tableTypeBBackgroundColor: #FFFFFF;
      --tableTypeCBackgroundColor: #FFFFFF;
      --tableTypeDBackgroundColor: #FFFFFF;
      --tableTypeEBackgroundColor: #FFFFFF;
      --tableTypeFBackgroundColor: #FFFFFF;
    }

    .counter-tag-list .tag-sequence {
      --tableTypeADarkColor: #FFFFFF;
      --tableTypeBDarkColor: #FFFFFF;
      --tableTypeCDarkColor: #FFFFFF;
      --tableTypeDDarkColor: #FFFFFF;
      --tableTypeEDarkColor: #FFFFFF;
      --tableTypeFDarkColor: #FFFFFF;

      --tableTypeABackgroundColor: #FFFFFF;
      --tableTypeBBackgroundColor: #FFFFFF;
      --tableTypeCBackgroundColor: #FFFFFF;
      --tableTypeDBackgroundColor: #FFFFFF;
      --tableTypeEBackgroundColor: #FFFFFF;
      --tableTypeFBackgroundColor: #FFFFFF;
    }
  }
  
}

@media only screen and (min-width: $desktopMinWidth) {
  .main-content.leave-home-safe-counter {

    .lang-wrapper {
      font-weight: 700;
      font-size: 40px;
    }

    .site-detail-wrapper {
      // height: calc(100% - 10px); 
      padding-bottom: 0px;
      .tag-sequence-list-wrapper {
        .title {
          margin-left: 0px;
          font-size: 64px;
        }
      }

      .background-site-info-head {
        padding: 38px 38px 0px 38px;
      }

      .background-site-info-body{
        border-top-left-radius: 100px;
        border-top-right-radius: 100px;
        padding: 38px 38px 0px 38px;
      }

      .background-site-info > .site-wrapper{
        .info{
          .title{
            font-size: 88.6977px;
          }

          .address .text{
            font-size: 56px;  
          }
        }
      }
    }
    
    .queue-bottom-btn-wrapper {
      .queue-button {
        font-size: 20px;
      }
    }

    .site-list-wrapper {

      .site-list-head {
        padding: 38px 38px 0px 38px;
      }

      .site-list-body {
        background-color: #ffffff;
        border-top-left-radius: 100px;
        border-top-right-radius: 100px;
        height: fit-content;
        padding: 38px 38px 0px 38px;
      }
  
      .site-wrapper {

        .background-site-info-head {
          padding: 38px 38px 0px 38px;
        }

        .title-wrapper {
          margin: 0 0 0 0;
          display: flex;
          flex-direction: column;
        }

        .site-date {
          font-size: 56px;
        }
  
        .site-title {
          font-size: 88.6977px;
        }

        .gulu-logo-wrapper {
          font-size: 40px;
        }
      }
      
    }
  }
}

@keyframes leave-home-safe-blink-animation-text {
  to {
    color: #51AE8A;
  }
}
@-webkit-keyframes leave-home-safe-blink-animation-text {
  to {
    color: #51AE8A;
  }
}
@keyframes leave-home-safe-blink-animation-text-time {
  to {
    color: #51AE8A;
  }
}
@-webkit-keyframes leave-home-safe-blink-animation-text-time {
  to {
    color: #51AE8A;
  }
}