@import "./global-variables.scss";

.reservation-wrapper-content,
.queue-wrapper-content {
  &.preview-wrapper {
    .preview-content {
      width: 90%;
      margin: 0 auto auto auto;
      .tnc-wrapper {
        .queue-title {
          width: 100%;
          padding: 0px;
        }
        .tnc-title {
          @include display-flex(normal, center);
          cursor: pointer;
        }
        .tnc-content {
          color: #ffffff;
          white-space: pre-line;
          font-size: 14px;
          width: 90%;
          margin: 0 auto 12px auto;
        }
      }

      .personal-data {
        @include display-flex(normal, center);
        font-size: 16px;
        cursor: pointer;
      }

      .checkbox-wrapper {
        @include display-flex(center, center);
        width: 16px;
        height: 16px;
        border: 1px solid #ffffff;
        border-radius: 3px;
        margin-right: 10px;
        .check-icon {
          width: 12px;
          height: 12px;
          color: #ffffff;
        }
      }
      .step-info {
        margin: 8px 0;
        .preview-title {
          font-size: 14px;
          margin-bottom: 5px;
        }
        .preview-text {
          @include display-flex(space-between, center);
          color: #05ff00;
          font-weight: bold;
          font-size: 14px;
          // text-align: center;
          line-height: 16px;
          word-break: break-word;
          .image {
            width: 24px;
            height: 24px;
            right: 20px;
            margin: 0 0 0 auto;
          }

          .table-name {
            @include display-flex(normal, normal);
            .text {
              font-size: 12px;
              font-weight: normal;
            }

            .background {
              width: 16px;
              height: 16px;
              @include display-flex(center, center);
              text-align: center;
              font-size: 16px;
            }
          }
        }
      }
      .personal-data-wrapper {
        @include display-flex(space-between, center);
        .personal-data-contant {
          & > .preview-text {
            margin-bottom: 5px;
          }
          .personal-data-phone {
            @include display-flex(normal, normal);
            .drop-down-text {
              margin-left: 5px;
            }
          }
        }
        .image {
          width: 24px;
          height: 24px;
          margin: 0 0 0 auto;
        }
      }
      .edit-button {
        cursor: pointer;
      }
    }
  }
}

@media only screen and (min-width: $desktopMinWidth) {
  .queue-wrapper-content {
    &.queue-preview-wrapper {
      .queue-preview-content {
        .step-info {
          margin: 20px 0;
          .preview-title {
            font-size: 20px;
          }
          .preview-text {
            font-size: 28px;
            line-height: initial;
          }
        }
        .tnc-wrapper {
          .tnc-title {
            font-size: 28px;
          }
          .tnc-content {
            font-size: 24px;
          }
        }
        .personal-data {
          font-size: 18px;
        }
      }
    }
  }
}
