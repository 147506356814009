@import './global-variables.scss';

.dialog-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 100;
  position: fixed;
  left: 0px;
  top: 0px;
  &.confirm {
    .dialog-buttons {
      @include display-flex(space-evenly, center);
    }
  }
  .dialog {
    background: #FFFFFF;
    border-radius: 10px;
    width: 271px;
    display: flex;
    flex-direction: column;
    .dialog-text {
      color: #000000;
      font-size: 14px;
      min-height: 60px;
      max-height: 70vh;
      margin: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      overflow-y: auto;
    }
    .dialog-line {
      border: 1px solid #ECECEC;
    }
    .dialog-button {
      color: #3E77CE;
      font-weight: bold;
      font-size: 12px;
      margin: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      cursor: pointer;
    }
  }
}