@import "../global-variables.scss";

#root .main-content.high-speed-rail {
  // background-color: #fff;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  th{
    font-weight: 700;
  }
}

.main-content.high-speed-rail::-webkit-scrollbar {
    width: 0px ;
}

.high-speed-rail {
  // --hsrPrimaryColor: #0066A6;
  // --textColor: var(--hsrPrimaryColor);
  // --titleColorByTheme: #000000;

  .en {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
  }

  .tv-detail-wrapper {
    font-family: 'Noto Serif HK', serif;

    width: 100%;
    height: 100%;
    background: #ECECEC;
    overflow-x: hidden;
    overflow-y: scroll;
    // -webkit-overflow-scrolling: touch;
    .en {
      font-family: 'Open Sans', sans-serif;
      font-weight: 700;
    }
    
    .reject-reason {
      @include display-flex(center, center);
      margin: 30px;
      font-weight: 700;
      font-size: 24px;
      color: #00ff38;
    }

    .ticket-type-title-wrapper {
      display: grid;
      grid-template-columns: min-content auto min-content;
      justify-content: center;
      align-items: center;
      gap: 36px;
      grid-template-areas: "A B C";
      font-size: clamp(50px, 4.5vw, 130px);
      padding: 19px 30px;
      font-weight: 700;
      min-height: 154px;

      .ticket-title-and-icon {
        img {
          width: clamp(80px, 8.6vw, 180px);
        }
      }

      .left {
        grid-area: A;
        width: clamp(80px, 8.6vw, 180px);
      }
    
      .title {
        grid-area: B;
        // text-align: center;
        line-height: 110%;

        .en {
          font-size: clamp(34px, 3.3vw, 110px);
          font-family: 'Open Sans', sans-serif;
          font-weight: 700;
        }
      }
    
      .right {
        grid-area: C;
        width: clamp(80px, 8.3vw, 160px);
      }
    }

    .background-site-info {
      background-color: #fff;
    }

    .tv-table {
      text-align: left;
      color: black;
      width: 100%;
      border-collapse: collapse;

      .tv-data-body td{
        padding: 40px;
        font-size: clamp(55px, 6vw, 200px);

        &.en {
          font-family: 'Open Sans', sans-serif;
          font-weight: 700;
        }
      }

      .tv-data-body.with-bg-color {
        background-color: #d8f0c9;
      }

      .tv-data-body td:first-child{
        width: 60%;
      }

      .tv-data-body td:last-child{
      }

      .tv-table-type-data {
        width: 15%;
      }

      .tv-queue-calling-display {
        width: 25%;
      }

      .tv-table-title.ticket-type-MAINLAND_BOUNDARY {
        background-color: #d8f0c9;
      }

      .tv-table-title.ticket-type-CROSS_BOUNDARY {
        background-color: #aeddf2;
      }

      .tv-table-title {
        font-size: clamp(32px, 3.2vw, 120px);
        font-weight: 500;
        line-height: 90%;
        .en {
          font-size: clamp(12px, 2.5vw, 90px);
        }

        th {
          padding-top: 20px;
          font-weight: 600;
        }
      }

      .tv-table-type-square img{
        border-radius: 4px;
        font-size: clamp(80px, 6.3vw, 150px);
        font-weight: 700;
        // width: clamp(88px, 5vw, 130px);
        height: clamp(88px, 8vw, 190px);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .tv-tableType-label {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        // gap: 30px;
        font-weight: 700;
        // padding-right: 30px;

        .tv-table-type-square {
          margin-right: 30px;
          border-radius: 5px;
        }

        .tc-label {
          font-size: clamp(37px, 4.2vw, 130px);
          line-height: 120%;
        }

        .en-label {
          font-size: clamp(12px, 2.3vw, 70px);
          font-family: 'Open Sans', sans-serif;
          font-weight: 700;
        }
      }

      .tv-tag-calling {
        border-radius: 4px;
        color: white;
        height: clamp(88px, 5.3vw, 130px);
        font-size: clamp(60px, 6.0vw, 200px);
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        position: relative;
        // width: fit-content;

        &.en {
          font-family: 'Open Sans', sans-serif;
          font-weight: 700;
        }
      }

      .reverse-tag-for-blink {
        position: absolute;
        width: calc(100% - 36px);
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        animation: blink-animation 1s step-end 4;
        -webkit-animation: blink-animation 1s step-end 4;
      }

    }

    .tv-table.horizontal{

      .ticket-type-title-wrapper {
        gap: 0px;
        grid-template-areas: "A B C";
        font-size: clamp(50px, 4.5vw, 70px);
        padding: 10px 15px;
        font-weight: 700;
        min-height: 100px;
  
        .ticket-title-and-icon {
          img {
            width: clamp(80px, 8.6vw, 90px);
          }
        }

        .left {
          width: clamp(70px, 8.6vw, 90px);
          display: none;
        }

        .title {
          grid-area: B;
          // text-align: center;
          line-height: 110%;
  
          .en {
            font-size: clamp(34px, 3.5vw, 55px);
            font-family: 'Open Sans', sans-serif;
            font-weight: 700;
          }
        }

        .right {
          grid-area: C;
          width: clamp(70px, 8.3vw, 80px);
          display: none;
        }
      }

      .tv-data-body td{
        padding: 20px;
        font-size: clamp(60px, 4.2vw, 80px);
      }

      .tv-table-title {
        font-size: clamp(18px, 2.8vw, 50px);

        .en {
          font-size: clamp(12px, 2.0vw, 35px);
        }
      }

      .tv-table-type-square img{
        height: clamp(60px, 5vw, 60px);
      }

      .tv-tableType-label {
        .tc-label {
          font-size: clamp(37px, 3.8vw, 52px);
          line-height: 120%;
        }

        .en-label {
          font-size: clamp(12px, 2.0vw, 25px);
        }
      }

      .tv-tag-calling {
        height: clamp(70px, 4.3vw, 80px);
        font-size: clamp(70px, 4.3vw, 80px);
      }

      .tv-queue-calling-display {
        width: 20%;
      }

    }

    .border-A {
      border: 10px solid $tableTypeAColor;
    }

    .border-B {
      border: 10px solid $tableTypeBColor;
    }

    .border-C {
      border: 10px solid $tableTypeCColor;
    }

    .border-D {
      border: 10px solid $tableTypeDColor;
    }

    .border-E {
      border: 10px solid $tableTypeEColor;
    }

    .border-F {
      border: 10px solid $tableTypeFColor;
    }

    .tv-tag-bottom-part {
      // border: 10px solid #000000;
      padding: 20px;
      background: black;

      .time {
        font-family: Wallpoet;
        font-weight: normal;
        font-size: 28px;
      }

      .zig-zag {
        background-image: linear-gradient(-45deg, #ffffff 14px, transparent 0), linear-gradient(45deg, #ffffff 14px, transparent 0);
        height: 5px;
        background-size: 5px 20px;

      }

      .zig-zag-bottom {
        background-image: linear-gradient(-135deg, #ffffff 14px, transparent 0), linear-gradient(135deg, #ffffff 14px, transparent 0);
        height: 5px;
        background-size: 5px 20px;
        background-position: 10px -15px;
      }

      // .zig-zag-bottom {
      //   &.zig-zag-F {
      //     @include zig-zag-bottom(#000000);
      //   }
      // }
      .ticket-sequence {
        font-size: 20px;

        .ticket {
          padding: 10px;
          background-color: #fff;
        }
      }
    }

    .tv-tag-detail {
      display: flex;
      justify-content: space-between;
    }

    .tv-tag-sequence-list {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .tv-table-type-label {
      padding: 20px;
      text-align: center;
      font-weight: 700;
      font-size: 30px;
    }

    .ticket-number {
      text-align: center;
      font-size: 50px;
    }

  }


}

@media only screen and (min-width: $desktopMinWidth) {
  body #root .main-content.high-speed-rail {
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
    max-width: 100%;
    border: 0px;
    border-radius: 0px;

    div::-webkit-scrollbar {
      width: 0px !important;
    }
  }
}

@keyframes blink-animation {
  50% {
    opacity: 1;
  }
}
@-webkit-keyframes blink-animation {
  50% {
    opacity: 1;
  }
}
