@import "./global-variables.scss";

.queue-title {
  @include display-flex(space-between, normal);
  width: calc(100% - 36px);
  margin: 5% 0 2% 0;
  padding: 0px 18px;
  font-weight: bold;
  font-size: 16px;
  // color: #eeecf1;
}

.queue-title-underline {
  background: #222222;
  border-radius: 1px;
  width: 90%;
  height: 1px;
  margin: 0 auto;
}

@media only screen and (min-width: $desktopMinWidth) {
  .queue-title {
    width: 96%;
    padding: 0px 2%;
    margin: 2% 0;
    font-size: 36px;
  }
}
