@import "./global-variables.scss";

.queue-wrapper-content {
  &.queue-special-request-wrapper {
    .special-request-checkbox-wrapper {
      padding: 16px;
      @include display-flex(normal, center);
      line-height: 18px;
      .checkbox {
        width: 22px;
        height: 22px;
        margin-right: 16px;
        background: #333333;
        background-clip: content-box;
        border-radius: 20%;
      }
    }
  }
}
