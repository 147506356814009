@import './global-variables.scss';

.gulu-logo-wrapper {
  grid-column-gap: 5px;
  align-items: center;
  display: grid;
  padding: 8px 16px 8px 8px;
  &.hidden {
    display: none;
  }

  .powered-by-wrapper {
    font-size: 12px;
    color: #DDD;
  }

  .image-wrapper{
    display: flex;
    grid-column-start: 2;
    .gulu-logo{
      .image{
        width: 43px;
        height: 43px;
        border-radius: 5px;
      }
      .image-bottom{
        margin-top: -43px;
        border-radius: 5px;
      }
    }
  }
  .lang-wrapper {
    @include display-flex(flex-end, center);
    // margin-right: 20px;
    align-self: start;
    grid-column-start: 3;
    flex-shrink: 0;
    .lang-button {
      padding: 5px 0px;
      cursor: pointer;
      &:not(:last-child) {
        &::after {
          display: inline-block;
          content: '/';
          margin: 0px 5px;
        }
      }
    }
  }
}