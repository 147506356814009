$pickupColor: #87ffe9;
$disableColor: #4a4a4a;
$desktopMinWidth: 720px;
$selectedColor: #1ab900;

:root {
  --tableTypeAColor: #fb8eb8;
  --tableTypeBColor: #75cd23;
  --tableTypeCColor: #52bffa;
  --tableTypeDColor: #ffae22;
  --tableTypeEColor: #db8eff;
  --tableTypeFColor: #d7d221;
  --tableTypeRColor: #BC67F3;

  --tableTypeADarkColor: #2c0917;
  --tableTypeBDarkColor: #162506;
  --tableTypeCDarkColor: #012131;
  --tableTypeDDarkColor: #1b1101;
  --tableTypeEDarkColor: #1c0228;
  --tableTypeFDarkColor: #1e1d00;
  --tableTypeRDarkColor: #1f132e;

  --tableTypeAColorOnWhiteBackground: #a0033f;
  --tableTypeBColorOnWhiteBackground: #32590d;
  --tableTypeCColorOnWhiteBackground: #04527c;
  --tableTypeDColorOnWhiteBackground: #704700;
  --tableTypeEColorOnWhiteBackground: #7d00b8;
  --tableTypeFColorOnWhiteBackground: #555207;
  --tableTypeRColorOnWhiteBackground: #8d50d9;

  --tableTypeABackgroundColor: #ffeef5;
  --tableTypeBBackgroundColor: #f5ffec;
  --tableTypeCBackgroundColor: #e2f5ff;
  --tableTypeDBackgroundColor: #fff5e2;
  --tableTypeEBackgroundColor: #fcf5ff;
  --tableTypeFBackgroundColor: #fffee8;
  --tableTypeRBackgroundColor: #eee0ff;

  --textColor: #FFFFFF;
  --titleColorByTheme: #FFFFFF;
  --backgroundColor: #000000;
  --themeBorderColor: #FFFFFF;
}

$tableTypeAColor: var(--tableTypeAColor);
$tableTypeBColor: var(--tableTypeBColor);
$tableTypeCColor: var(--tableTypeCColor);
$tableTypeDColor: var(--tableTypeDColor);
$tableTypeEColor: var(--tableTypeEColor);
$tableTypeFColor: var(--tableTypeFColor);
$tableTypeRColor: var(--tableTypeRColor);

$tableTypeADarkColor: var(--tableTypeADarkColor);
$tableTypeBDarkColor: var(--tableTypeBDarkColor);
$tableTypeCDarkColor: var(--tableTypeCDarkColor);
$tableTypeDDarkColor: var(--tableTypeDDarkColor);
$tableTypeEDarkColor: var(--tableTypeEDarkColor);
$tableTypeFDarkColor: var(--tableTypeFDarkColor);
$tableTypeRDarkColor: var(--tableTypeRDarkColor);

$tableTypeAColorOnWhiteBackground: var(--tableTypeAColorOnWhiteBackground);
$tableTypeBColorOnWhiteBackground: var(--tableTypeBColorOnWhiteBackground);
$tableTypeCColorOnWhiteBackground: var(--tableTypeCColorOnWhiteBackground);
$tableTypeDColorOnWhiteBackground: var(--tableTypeDColorOnWhiteBackground);
$tableTypeEColorOnWhiteBackground: var(--tableTypeEColorOnWhiteBackground);
$tableTypeFColorOnWhiteBackground: var(--tableTypeFColorOnWhiteBackground);
$tableTypeRColorOnWhiteBackground: var(--tableTypeRColorOnWhiteBackground);

$tableTypeABackgroundColor: var(--tableTypeABackgroundColor);
$tableTypeBBackgroundColor: var(--tableTypeBBackgroundColor);
$tableTypeCBackgroundColor: var(--tableTypeCBackgroundColor);
$tableTypeDBackgroundColor: var(--tableTypeDBackgroundColor);
$tableTypeEBackgroundColor: var(--tableTypeEBackgroundColor);
$tableTypeFBackgroundColor: var(--tableTypeFBackgroundColor);
$tableTypeRBackgroundColor: var(--tableTypeRBackgroundColor);

$textColor: var(--textColor);
$titleColorByTheme: var(--titleColorByTheme);
$backgroundColor: var(--backgroundColor);

$queueContentBottomPadding: 75px;
$desktopQueueContentBottomPadding: 90px;
$desktopMinWidth: 800px;
$desktopLargeMinWidth: 1512px;

@mixin display-flex($justify, $align, $direction: row) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
}

@mixin calendar-hover {
  &:hover {
    &:not(.disable) {
      background: none;
      background-color: $hoverColor;
      color: #ffffff;
      .preorder {
        .dot {
          background-color: #ffffff;
          &.empty {
            background-color: transparent;
            border: 1px solid #ffffff;
          }
        }
      }
    }
  }
}

%zig-zag-base {
  background-position: 10px 0px;
  background-repeat: repeat-x;
  background-size: 20px 20px;
  content: " ";
  display: block;
  width: 100%;
  height: 20px;
}

@mixin zig-zag($color, $size: 20px) {
  background-image: linear-gradient(-45deg, $color 14px, transparent 0),
    linear-gradient(45deg, $color 14px, transparent 0);
  @extend %zig-zag-base;
  height: $size;
  background-size: $size 20px;
}

@mixin zig-zag-bottom($color, $size: 20px) {
  background-image: linear-gradient(-135deg, $color 14px, transparent 0),
    linear-gradient(135deg, $color 14px, transparent 0);
  @extend %zig-zag-base;
  height: $size;
  background-size: $size 20px;
}

@mixin zig-zag-line($color, $bottomColor) {
  background-image: linear-gradient(-45deg, $color 14px, transparent 0),
    linear-gradient(45deg, $color 14px, transparent 0);
  @extend %zig-zag-base;
  background-position: 10px 4.5px;
  background-size: 12px 20px;
  // &:after {
  //   background-image: linear-gradient(-45deg, $bottomColor 14px, transparent 0),
  //     linear-gradient(45deg, $bottomColor 14px, transparent 0);
  //   @extend %zig-zag-base;
  //   background-position: 10px 4.5px;
  //   background-size: 12px 20px;
  //   margin-top: 1.5px;
  // }
}
