@import "./global-variables.scss";

.tag-sequence {
  width: calc(100% - 22px);
  padding: 10px;
  margin: 0px auto 10px auto;
  border: 1px solid transparent;
  border-radius: 5px;
  @include display-flex(center, center, column);
  &.blink {
    animation: blink-animation 1s 1s linear 4;
    -webkit-animation: blink-animation 1s 1s linear 4;
    .text {
      .tag-label {
        animation: blink-animation-text 1s 1s linear 4;
        -webkit-animation: blink-animation-text 1s 1s linear 4;
      }
    }
    .time {
      .time-label {
        animation: blink-animation-text 1s 1s linear 4;
        -webkit-animation: blink-animation-text 1s 1s linear 4;
      }
    }
  }
  .table-type-label {
    font-size: 14px;
    color: #ffffff;
  }
  .ticket-number {
    @include display-flex(center, center);
    width: 100%;
    font-weight: bold;
    font-size: 28px;
    line-height: 28px;
    position: relative;
    .image path {
      background-color: #000000;
    }
    .reload-icon {
      right: 5%;
      position: absolute;
    }
    .text {
      @include display-flex(center, center);
      .sound-icon {
        width: 27px;
        height: 20px;
        margin-right: 10px;
      }
      .counter-icon {
        width: 6px;
        height: 10px;
        margin: 0px 8px;
      }
      .counter-name {
        @include display-flex(center, center);
        // width: 35px;
        // height: 35px;
        padding: 0px 15px;
        border-radius: 50px;
        font-size: 28px;
        color: #ffffff;
        &.bg-A {
          background-color: $tableTypeAColorOnWhiteBackground;
        }
        &.bg-B {
          background-color: $tableTypeBColorOnWhiteBackground;
        }
        &.bg-C {
          background-color: $tableTypeCColorOnWhiteBackground;
        }
        &.bg-D {
          background-color: $tableTypeDColorOnWhiteBackground;
        }
        &.bg-E {
          background-color: $tableTypeEColorOnWhiteBackground;
        }
        &.bg-F {
          background-color: $tableTypeFColorOnWhiteBackground;
        }
        &.bg-R {
          background-color: $tableTypeRColorOnWhiteBackground;
        }
        &.long {
          font-size: 18px;
        }
      }
    }
  }
  .zig-zag-A {
    @include zig-zag-line($tableTypeAColor, $tableTypeADarkColor);
  }
  .zig-zag-B {
    @include zig-zag-line($tableTypeBColor, $tableTypeBDarkColor);
  }
  .zig-zag-C {
    @include zig-zag-line($tableTypeCColor, $tableTypeCDarkColor);
  }
  .zig-zag-D {
    @include zig-zag-line($tableTypeDColor, $tableTypeDDarkColor);
  }
  .zig-zag-E {
    @include zig-zag-line($tableTypeEColor, $tableTypeEDarkColor);
  }
  .zig-zag-F {
    @include zig-zag-line($tableTypeFColor, $tableTypeFDarkColor);
  }
  .zig-zag-R {
    @include zig-zag-line($tableTypeRColor, $tableTypeRDarkColor);
  }
  .time {
    font-family: Wallpoet;
    font-weight: normal;
    font-size: 28px;
  }
  .ticket-sequence-wrapper {
    @include display-flex(center, center);
    margin-top: 10px;
    .image {
      margin-right: 5px;

      path {
        fill:  currentColor;
      }
      // &.A {
      //   path {
      //     fill:  $tableTypeAColor;
      //   }
      // }
      // &.B {
      //   path {
      //     fill:  $tableTypeBColor;
      //   }
      // }
      // &.C {
      //   path {
      //     fill:  $tableTypeCColor;
      //   }
      // }
      // &.D {
      //   path {
      //     fill:  $tableTypeDColor;
      //   }
      // }
      // &.E {
      //   path {
      //     fill:  $tableTypeEColor;
      //   }
      // }
      // &.F {
      //   path {
      //     fill:  $tableTypeFColor;
      //   }
      // }
      // &.R {
      //   path {
      //     fill:  $tableTypeRColor;
      //   }
      // }
    }
    .label {
      margin-right: 10px;
      font-weight: 500;
    }
    .ticket-sequence {
      .zig-zag {
        @include zig-zag(#ffffff, 5px);
      }
      .zig-zag-bottom {
        @include zig-zag-bottom(#ffffff, 5px);
        background-position: 10px -15px;
      }
      .ticket {
        min-width: 60px;
        padding: 3px;
        font-size: 20px;
        text-align: center;
        background-color: #ffffff;
        &.text-A {
          color: $tableTypeAColorOnWhiteBackground;
        }
        &.text-B {
          color: $tableTypeBColorOnWhiteBackground;
        }
        &.text-C {
          color: $tableTypeCColorOnWhiteBackground;
        }
        &.text-D {
          color: $tableTypeDColorOnWhiteBackground;
        }
        &.text-E {
          color: $tableTypeEColorOnWhiteBackground;
        }
        &.text-F {
          color: $tableTypeFColorOnWhiteBackground;
        }
        &.text-R {
          color: $tableTypeRColorOnWhiteBackground;
        }
      }
    }
  }
  .quota {
    margin-top: 10px;
    font-size: 18px;
    font-weight: 700;
  }
}


@media only screen and (min-width: $desktopMinWidth) {
  .tag-sequence {
    width: calc(100% - 20px);
    padding: 10px;
    margin: 0px auto 30px auto;
    .table-type-label {
      font-size: 27px;
    }
    .ticket-number {
      font-size: 42px;
      line-height: 42px;
      .text {
        .sound-icon {
          width: 50px;
          height: 37px;
          margin-right: 5px;
        }
      }
    }
    .time {
      font-size: 38px;
    }
    .ticket-sequence-wrapper {
      margin-top: 25px;
      margin-bottom: 5px;
      .image {
        width: 27px;
        height: 40px;
        margin-right: 10px;
      }
      .label {
        margin-right: 15px;
        font-size: 24px;
      }
      .ticket-sequence {
        .ticket {
          min-width: 125px;
          font-size: 38px;
        }
      }
    }
    .quota {
      margin-top: 25px;
      margin-bottom: 5px;
      font-size: 36px;
    }
  }
}


@keyframes blink-animation {
  to {
    border: 1px solid #ffffff;
  }
}
@-webkit-keyframes blink-animation {
  to {
    border: 1px solid #ffffff;
  }
}

@keyframes blink-animation-text {
  to {
    opacity: 0;
  }
}
@-webkit-keyframes blink-animation-text {
  to {
    opacity: 0;
  }
}