@import './global-variables.scss';

.pickup-wrapper {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  .pickup-content-wrapper {
    flex: 1;
  }
  .pickup-content {
    border: 3px solid $pickupColor;
    box-sizing: border-box;
    backdrop-filter: blur(4px); 
    border-radius: 20px;
    color: $pickupColor;
    margin: 8px;
    .title-wrapper{
      @include display-flex(space-between, normal);
      padding: 16px 0;
      .rest-logo{
        margin: 0 8px;
        .image{
          width: 50px;
          height: 50px;
        }
      }
      .title{
        @include display-flex(center, center);
        width: 70%;
        .pickup-logo{
          margin-right: 5px;
          width: 30px;
          height: 30px;
        }
        .text{
          @include display-flex(center, center, column);
          font-weight: bold;
          font-size: 20px;
          text-align: center;
          white-space:pre-line;
          .text-zh{
            @include display-flex(center, center);
          }
        }
      }
    }

    .middle-line{
      width: 95%;
      background: $pickupColor;
      backdrop-filter: blur(4px);
      border-radius: 20px;
      height: 3px;
      margin: 0 auto;
    }
    .pickup-list{
      @include display-flex(normal, normal);
      flex-wrap: wrap;
      padding: 16px 0px;
      line-height: 30px;
      .text{
        font-weight: 900;
        font-size: 36px;
        font-family: poppins;
        text-align: center;
        margin: 18px;
        &.blink {
          animation: pickup-blink-animation 1s 1s linear 4;
          -webkit-animation: pickup-blink-animation 1s 1s linear 4;
        }
      }
    }
    .pickup-gulu-logo {
      width: 50px;
      height: 50px;
    }
  }

  .pickup-gulu-logo{
    margin: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .image{
      width: 50px;
      height: 50px;
    }
    .image-bottom{
      margin-top: -50px;
    }
  }
}

@keyframes pickup-blink-animation {
  to {
    color: #ff879d;
  }
}
@-webkit-keyframes pickup-blink-animation {
  to {
    color: #ff879d;
  }
}