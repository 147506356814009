@import "./global-variables.scss";

$height: 12px;
$halfheight: 6px;
$thickness: 120%; // increase to make the line thicker
$offset: 4px;

.ticket-wrapper {
  &.queue-wrapper {
    height: 100%;
    padding-bottom: 0px;
  }
  // .title {
  //   width: 90%;
  //   margin: 0px auto;
  // }
  .tag-sequence-list,
  .counter-tag-list {
    width: 90%;
    margin: 0px auto;
    .tag-sequence {
      &:first-child {
        margin: 15px auto 15px auto;
      }
      &:not(:last-child) {
        margin: 0px auto 15px auto;
      }
    }
  }
  .ticket-box {
    width: 90%;
    margin: 0px auto;
    padding-bottom: 15px;
    .zig-zag-A {
      @include zig-zag($tableTypeADarkColor);
    }
    .zig-zag-bottom {
      &.zig-zag-A {
        @include zig-zag-bottom($tableTypeADarkColor);
      }
    }
    .zig-zag-B {
      @include zig-zag($tableTypeBDarkColor);
    }
    .zig-zag-bottom {
      &.zig-zag-B {
        @include zig-zag-bottom($tableTypeBDarkColor);
      }
    }
    .zig-zag-C {
      @include zig-zag($tableTypeCDarkColor);
    }
    .zig-zag-bottom {
      &.zig-zag-C {
        @include zig-zag-bottom($tableTypeCDarkColor);
      }
    }
    .zig-zag-D {
      @include zig-zag($tableTypeDDarkColor);
    }
    .zig-zag-bottom {
      &.zig-zag-D {
        @include zig-zag-bottom($tableTypeDDarkColor);
      }
    }
    .zig-zag-E {
      @include zig-zag($tableTypeEDarkColor);
    }
    .zig-zag-bottom {
      &.zig-zag-E {
        @include zig-zag-bottom($tableTypeEDarkColor);
      }
    }
    .zig-zag-F {
      @include zig-zag($tableTypeFDarkColor);
    }
    .zig-zag-bottom {
      &.zig-zag-F {
        @include zig-zag-bottom($tableTypeFDarkColor);
      }
    }
  }
  .ticket-box-content {
    // border-radius: 5px;
    @include display-flex(center, center, column);
    .ticket-content {
      width: 100%;
      .ticket-number {
        @include display-flex(center, center);
        text-align: center;
        color: #ffffff;
        font-weight: bold;
        font-size: 30px;
        padding: 3px;
        position: relative;
        .table-size {
          @include display-flex(center, center);
          position: absolute;
          right: 5%;
          font-size: 25px;
          padding-top: 3px;
          line-height: 25px;
          .image {
            width: 22px;
            margin-right: 3px;
          }
        }
        .counter-name-wrapper {
          @include display-flex(center, center);
          // position: absolute;
          // right: 5%;
          .counter-icon {
            width: 6px;
            height: 10px;
            margin: 0px 8px;
            path {
              fill: #ffffff;
            }
          }
          .counter-name {
            @include display-flex(center, center);
            // width: 35px;
            height: 35px;
            padding: 0px 10px;
            border-radius: 50px;
            font-size: 28px;
            background-color: #ffffff;
            &.long {
              font-size: 20px;
            }
          }
        }
      }
      .table-type-label {
        width: 100%;
        margin: 10px auto;
        text-align: center;
      }
      .ticket-date {
        margin-top: 10px;
        text-align: center;
        color: white;
      }
      .qrcode-wrapper {
        @include display-flex(center, center, column);
        position: relative;
        .overlay-text {
          margin-top: 10px;
        }
        .qrcode-background {
          // border-radius: 6px;
          padding: 10px;
          margin: 10px;
          position: relative;
          background-color: #ffffff;
          &.overlay {
            margin: 10px;
            &::after {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0px;
              left: 0px;
              background-color: rgba(0,0,0,0.9);
              content: '';
            }
          }
          .qrcode {
            display: block;
          }
        }
        .count-down {
          padding: 10px 0px;
          font-size: 28px;
          color: #ffffff;
        }
        .call-timestamp {
          color: #ffffff;
        }
        .trash-icon {
          position: absolute;
          right: 5%;
          top: 10px;
          color: #ffffff;
          cursor: pointer;
        }
      }
      .reminder-message {
        width: 90%;
        margin: 2px auto 9px auto;
      }
      .ticket-detail-wrapper {
        @include display-flex(normal, normal, column);
        flex-direction: column;

        .ticket-detail {
          @include display-flex(normal, normal, column);
          width: 100%;
          .survey-info-wrapper {
            width: 90%;
            margin: 9px auto 0px auto;
            .question-title {
              font-size: 14px;
              color: #ffffff;
            }
            .question-text {
              margin: 2px auto 9px auto;
            }
          }
        }
      }

      .ticket-title {
        @include display-flex(normal, normal, column);
        color: $titleColorByTheme;
        font-weight: normal;
        font-size: 14px;
        margin: 9px auto 2px auto;
        width: 90%;
      }

      .ticket-text {
        @include display-flex(normal, flex-start, column);
        color: $textColor;
        font-weight: 700;
        font-size: 14px;
        margin: 2px auto 9px auto;
        width: 90%;
      }
      .date-wrapper {
        @include display-flex(normal, flex-start);
        margin: 2px auto 9px auto;
        width: 90%;
        .ticket-text {
          width: 100%;
          margin: 0px;
        }
        .edit-icon {
          width: 23px;
          height: 23px;
          color: #ffffff;
        }
        .edit-icon:hover {
          cursor: pointer;
        }
      }
      .subscribed-message {
        @include display-flex(normal, center);
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        margin-left: auto;
        margin-right: auto;
        max-width: 90%;
      }
      .subscription-wrapper {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        margin-left: auto;
        margin-right: auto;
        max-width: 90%;
        .subscription-title {
          font-size: 14px;
          padding-bottom: 10px;
          color: white;
        }
        .ticket-email {
          .email-delivery-screenshot-reminder {
            color: #7EB840;
            font-weight: 700;
            font-size: 16px;
            display: flex;
            align-items: center;
            .email-delivery-reminder-icon {
              margin-right: 5px;
              height: 17px;
            }
          }
        }
        .whatsapp-subscribe-button {
          margin-left: auto;
          margin-right: auto;
          max-width: calc(90% - 20px);
          padding: 10px;
          border-radius: 10px;
          background-color: #25D366;
          display: flex;
          align-items: center;
          color: #ffffff;
          cursor: pointer;
          .whatsapp-icon {
            width: 17px;
            height: 17px;
            margin-right: 5px;
            flex-shrink: 0;
          }
        }
      }
    }
  }

  .background-site-info {
    background-color: initial;
  }

  .blinking .ticket-content.background-dark-A{
    background-color: transparent !important;
    animation: ticket-blink-a 3s step-end infinite;
    -webkit-animation: ticket-blink-a 3s step-end infinite;
  }

  .blinking .zig-zag-A{
    background-color: transparent !important;
      animation: ticket-zig-zag-blink-a 3s step-end infinite;
      -webkit-animation: ticket-zig-zag-blink-a 3s step-end infinite;
    }

    .blinking .ticket-content.background-dark-B{
      background-color: transparent !important;
      animation: ticket-blink-b 3s step-end infinite;
      -webkit-animation: ticket-blink-b 3s step-end infinite;
    }
  
    .blinking .zig-zag-B{
      background-color: transparent !important;
        animation: ticket-zig-zag-blink-b 3s step-end infinite;
        -webkit-animation: ticket-zig-zag-blink-b 3s step-end infinite;
      }

  .blinking .ticket-content.background-dark-C{
    background-color: transparent !important;
    animation: ticket-blink-c 3s step-end infinite;
    -webkit-animation: ticket-blink-c 3s step-end infinite;
  }

  .blinking .zig-zag-C{
    background-color: transparent !important;
      animation: ticket-zig-zag-blink-c 3s step-end infinite;
      -webkit-animation: ticket-zig-zag-blink-c 3s step-end infinite;
    }

    .blinking .ticket-content.background-dark-D{
      background-color: transparent !important;
      animation: ticket-blink-d 3s step-end infinite;
      -webkit-animation: ticket-blink-d 3s step-end infinite;
    }
  
    .blinking .zig-zag-D{
      background-color: transparent !important;
        animation: ticket-zig-zag-blink-d 3s step-end infinite;
        -webkit-animation: ticket-zig-zag-blink-d 3s step-end infinite;
    }

    .blinking .ticket-content.background-dark-E{
      background-color: transparent !important;
      animation: ticket-blink-e 3s step-end infinite;
      -webkit-animation: ticket-blink-e 3s step-end infinite;
    }
  
    .blinking .zig-zag-E{
      background-color: transparent !important;
        animation: ticket-zig-zag-blink-e 3s step-end infinite;
        -webkit-animation: ticket-zig-zag-blink-e 3s step-end infinite;
    }
    .blinking .ticket-content.background-dark-F{
      background-color: transparent !important;
      animation: ticket-blink-f 3s step-end infinite;
      -webkit-animation: ticket-blink-f 3s step-end infinite;
    }
  
    .blinking .zig-zag-F{
      background-color: transparent !important;
        animation: ticket-zig-zag-blink-f 3s step-end infinite;
        -webkit-animation: ticket-zig-zag-blink-f 3s step-end infinite;
    }
    @keyframes ticket-zig-zag-blink-a {
      0%{
        filter: brightness(1)
      }
    
      50% {
        filter: brightness(3)
      }
    }
  
    @keyframes ticket-blink-a {
      0% {
        backdrop-filter: brightness(100%);
      }
    
      50% {
        backdrop-filter: brightness(300%);
      }
    }

    @keyframes ticket-zig-zag-blink-b {
      0%{
        filter: brightness(1)
      }
    
      50% {
        filter: brightness(3)
      }
    }
  
    @keyframes ticket-blink-b {
      0% {
        backdrop-filter: brightness(100%);
      }
    
      50% {
        backdrop-filter: brightness(300%);
      }
    }

    @keyframes ticket-zig-zag-blink-c {
      0%{
        filter: brightness(1)
      }
    
      50% {
        filter: brightness(3)
      }
    }

    @keyframes ticket-blink-c {
      0% {
        backdrop-filter: brightness(100%);
      }
    
      50% {
        backdrop-filter: brightness(300%);
      }
    }

    @keyframes ticket-zig-zag-blink-d {
      0% {
        filter: brightness(1)
      }
    
      50% {
        filter: brightness(3)
      }
    }

    @keyframes ticket-blink-d {
      0% {
        backdrop-filter: brightness(100%);
      }
    
      50% {
        backdrop-filter: brightness(300%);
      }
    }

    @keyframes ticket-zig-zag-blink-e {
      0%{
        filter: brightness(1)
      }
    
      50% {
        filter: brightness(3)
      }
    }

    @keyframes ticket-blink-e {
      0% {
        backdrop-filter: brightness(100%);
      }
    
      50% {
        backdrop-filter: brightness(300%);
      }
    }

    @keyframes ticket-zig-zag-blink-f {
      0%{
        filter: brightness(1)
      }
    
      50% {
        filter: brightness(3)
      }
    }

    @keyframes ticket-blink-f {
      0% {
        backdrop-filter: brightness(100%);
      }
    
      50% {
        backdrop-filter: brightness(300%);
      }
    }
}

.middle-line {
  width: 90%;
  margin: 0 auto;
  background: #b0afb5;
  backdrop-filter: blur(4px);
  border-radius: 20px;
  height: 1px;
}

@media only screen and (min-width: $desktopMinWidth) {
  .main-content {
    .ticket-wrapper {
      height: calc(100% - 38px);
      margin-bottom: 38px;
      .ticket-box-content {
        .ticket-content {
          .ticket-number {
            font-size: 48px;
            .table-size {
              font-size: 48px;
              .image {
                width: 35px;
                height: 37px;
              }
            }
          }
          .table-type-label {
            font-size: 28px;
          }
          .ticket-title {
            font-size: 20px;
          }
          .ticket-text {
            font-size: 28px;
          }
        }
      }
    }
  }
}
