@import "../global-variables.scss";


.cichk {
  --cichkPrimaryColor: #0066A6;
  --textColor: var(--cichkPrimaryColor);
  --titleColorByTheme: #000000;
  .select-wrapper {
    .picker {
      border: 1px solid #000000;
      color: #000000;
    }
    .image {
      fill: #000000;
    }
  }
  .input-text-wrapper {
    .input-text {
      border: 1px solid #000000;
      color: #000000;
    }
  }
  .surname-wrapper {
    .surname {
      border: 1px solid #000000;
      color: #000000;
      .name-title-wrapper {
        .name-title-select {
          color: #000000;
        }
        .image {
          fill: #000000;
        }
      }
      .surname-input {
        color: #000000;
      }
    }
  }
  .drop-down-text-wrapper {
    .drop-down-text {
      border: 1px solid #000000;
      color: #000000;
      .drop-down-wrapper {
        .drop-down-select {
          color: #000000;
        }
        .image {
          fill: #000000;
        }
      }
      .text-input {
        color: #000000;
      }
    }
  }
  .select-time-wrapper {
    .options {
      .option {
        border: 1px solid #000000;
        &.selected {
          border: 1px solid var(--cichkPrimaryColor);
          background-color: var(--cichkPrimaryColor);
          color: #ffffff;
        }
      }
    }
  }
  .checkbox-list-wrapper {
    .checkbox-wrapper {
      .checkbox {
        border: 1px solid #000000;
        .check-icon {
          color: var(--cichkPrimaryColor);
        }
      }
    }
  }
  .rating-wrapper {
    .image {
      color: #000000;
      &.fill {
        .background {
          fill: var(--cichkPrimaryColor);
        }
      }
    }
  }
}

.main-content.cichk {
  background-color: #ffffff;
  color: #000000;
  .header-wrapper {
    .cichk-header-wrapper {
      @include display-flex(center, center);
      width: 100%;
      padding: 14px 0px;
      position: relative;
      background-color: var(--cichkPrimaryColor);
      .banner {
        width: 200px;
      }
      .lang-wrapper {
        @include display-flex(flex-end, center);
        position: absolute;
        right: 10px;
        top: 10px;
        align-self: start;
        grid-column-start: 3;
        flex-shrink: 0;
        cursor: pointer;
        .lang-button {
          padding: 5px 0px;
          color: #ffffff;
          &:not(:last-child) {
            &::after {
              display: inline-block;
              content: '/';
              margin: 0px 5px;
            }
          }
        }
      }
    }
    .background-site-info {
      &.background-site-info-head {
        background-color: #F2F2F2;
      }
    }
    .site-wrapper {
      .site {
        .info {
          .address {
            color: var(--cichkPrimaryColor);
            .location-icon {
              stroke: var(--cichkPrimaryColor);
            }
          }
        }
      }
    }
  }
  .calendar {
    .header {
      background-color: var(--cichkPrimaryColor);
    }
    .calendar-content {
      tbody {
        td {
          .date-wrapper {
            color: var(--cichkPrimaryColor);
            &.disable {
              color: #c3bfcb;
            }
            &.selected {
              background-color: var(--cichkPrimaryColor);
              color: #ffffff;
            }
          }
        }
      }
    }
  }
  .site-detail-wrapper {
    background-color: #ffffff;
    .background-site-info {
      &.background-site-info-head {
        background-color: #F2F2F2;
      }
      background-color: #ffffff;
    }
    .tag-sequence-list-wrapper {
      .current-date {
        font-weight: 700;
        color: #424242;
      }
    }
  }
  .bottom-btn-wrapper {
    background-color: #ffffff;
    .queue-button  {
      width: 145px;
      height: 50px;
      border-radius: 0px;
      background-color: var(--cichkPrimaryColor);
      color: #ffffff;
    }
  }
  .reservation-wrapper,
  .queue-wrapper {
    .background-site-info {
      background-color: #F2F2F2;
    }
    .queue-title {
      justify-content: center;
    }
    input[type="radio"] {
      border: 2px solid #000000;
    }
    input[type="radio"]:checked {
      box-shadow: inset 0 0 0 2px #ffffff;
      background: var(--cichkPrimaryColor);
    }
    .service-type-radio-wrapper {
      .image {
        path {
        fill: #000000;
        }
      }
    }
    .reservation-wrapper-content,
    .queue-wrapper-content {
      &.preview-wrapper {
        .checkbox-wrapper {
          border: 1px solid #000000;
          .check-icon {
            color: var(--cichkPrimaryColor);
          }
        }
        .step-info {
          .preview-text {
            color: #000000;
          }
        }
        .tnc-wrapper {
          .tnc-content{
            color: #000000;
          }
        }
      }
    }
    &.ticket-wrapper {
      .ticket-box {
        width: 100%;
        .background-dark-A,
        .background-dark-B,
        .background-dark-C,
        .background-dark-D,
        .background-dark-E,
        .background-dark-F,
        .background-dark-R {
          background-color: transparent;
        }
        &.blinking .ticket-content.background-dark-A{
          background-color: transparent !important;
          animation: cichk-ticket-blink-a 3s step-end infinite;
          -webkit-animation: cichk-ticket-blink-a 3s step-end infinite;
        }
      
        &.blinking .zig-zag-A{
          background-color: transparent !important;
          animation: none;
        }
      
        &.blinking .ticket-content.background-dark-B{
          background-color: transparent !important;
          animation: cichk-ticket-blink-b 3s step-end infinite;
          -webkit-animation: cichk-ticket-blink-b 3s step-end infinite;
        }
      
        &.blinking .zig-zag-B{
          background-color: transparent !important;
          animation: none;
          -webkit-animation: none;
        }
      
        &.blinking .ticket-content.background-dark-C{
          background-color: transparent !important;
          animation: cichk-ticket-blink-c 3s step-end infinite;
          -webkit-animation: cichk-ticket-blink-c 3s step-end infinite;
        }
      
        &.blinking .zig-zag-C{
          background-color: transparent !important;
          animation: none;
          -webkit-animation: none;
        }
      
        &.blinking .ticket-content.background-dark-D{
          background-color: transparent !important;
          animation: cichk-ticket-blink-d 3s step-end infinite;
          -webkit-animation: cichk-ticket-blink-d 3s step-end infinite;
        }
      
        &.blinking .zig-zag-D{
          background-color: transparent !important;
          animation: none;
          -webkit-animation: none;
        }
    
        &.blinking .ticket-content.background-dark-E{
          background-color: transparent !important;
          animation: cichk-ticket-blink-e 3s step-end infinite;
          -webkit-animation: cichk-ticket-blink-e 3s step-end infinite;
        }
      
        &.blinking .zig-zag-E{
          background-color: transparent !important;
          animation: none;
          -webkit-animation: none;
        }
        &.blinking .ticket-content.background-dark-F{
          background-color: transparent !important;
          animation: cichk-ticket-blink-f 3s step-end infinite;
          -webkit-animation: cichk-ticket-blink-f 3s step-end infinite;
        }
      
        &.blinking .zig-zag-F{
          background-color: transparent !important;
          animation: none;
          -webkit-animation: none;
        }
        @keyframes cichk-ticket-blink-a {
          0% {
            backdrop-filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(77deg) brightness(101%) contrast(103%);
          }
        
          50% {
            backdrop-filter: brightness(0) saturate(100%) invert(75%) sepia(37%) saturate(213%) hue-rotate(288deg) brightness(104%) contrast(98%);
          }
        }
        @keyframes cichk-ticket-blink-b {
          0% {
            backdrop-filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(77deg) brightness(101%) contrast(103%);
          }
        
          50% {
            backdrop-filter: brightness(0) saturate(100%) invert(87%) sepia(68%) saturate(307%) hue-rotate(30deg) brightness(97%) contrast(89%);
          }
        }
        @keyframes cichk-ticket-blink-c {
          0% {
            backdrop-filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(77deg) brightness(101%) contrast(103%);
          }
        
          50% {
            backdrop-filter: brightness(0) saturate(100%) invert(75%) sepia(41%) saturate(299%) hue-rotate(166deg) brightness(105%) contrast(98%);
          }
        }
        @keyframes cichk-ticket-blink-d {
          0% {
            backdrop-filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(77deg) brightness(101%) contrast(103%);
          }
        
          50% {
            backdrop-filter: brightness(0) saturate(100%) invert(80%) sepia(33%) saturate(596%) hue-rotate(346deg) brightness(104%) contrast(102%);
          }
        }
        @keyframes cichk-ticket-blink-e {
          0% {
            backdrop-filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(77deg) brightness(101%) contrast(103%);
          }
        
          50% {
            backdrop-filter: brightness(0) saturate(100%) invert(75%) sepia(63%) saturate(313%) hue-rotate(213deg) brightness(99%) contrast(104%);
          }
        }
        @keyframes cichk-ticket-blink-f {
          0% {
            backdrop-filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(77deg) brightness(101%) contrast(103%);
          }
        
          50% {
            backdrop-filter: brightness(0) saturate(100%) invert(91%) sepia(21%) saturate(775%) hue-rotate(3deg) brightness(105%) contrast(87%);
          }
        }
        .ticket-box-content {
          // background-color: #ffffff;
          .ticket-content {
            // background-color: #ffffff;
            .table-type-label {
              font-weight: 700;
            }
            .ticket-text {
              color: #000000;
            }
            .dropdown-arrow {
              .image {
                stroke: #000000;
              }
            }
            .drop-down-content  {
              color: #000000;
            }
            .ticket-email {
              .email-delivery-reminder {
                color: #000000;
              }
              .ticket-email-input-field {
                border: 1px solid #000000;
                box-shadow: none;
              }
            }
            .qrcode-wrapper {
              .call-timestamp {
                color: #000000;
              }
              .trash-icon {
                color: #000000;
              }
            }
            .survey-info-wrapper {
              .question-title {
                color: #000000;
              }
              .question-text {
                color: #000000;
              }
            }
          }
        }
        .zig-zag {
          display: none;
        }
      }
    }
  }
  .reservation-bottom-btn-wrapper,
  .queue-bottom-btn-wrapper {
    background-color: #ffffff;
    .reservation-button,
    .queue-button  {
      &.disable {
        background: #7B7B7B;
        cursor: initial;
      }
      width: 145px;
      border-radius: 0px;
      background-color: var(--cichkPrimaryColor);
      color: #ffffff;
      .arrow {
        path {
          stroke: #ffffff;
        }
      }
    }
  }
  .reservation-single-button-wrapper,
  .queue-single-button-wrapper {
    .single-button {
      border: 1px solid #000000;
    }
  }
}

@media only screen and (min-width: $desktopMinWidth) {
  body {
    #root {
      .hang-seng {
        div {
          &::-webkit-scrollbar-thumb {
            background-color: var(--cichkPrimaryColor);
          }
        }
      }
    }
  }
}
