@import "./global-variables.scss";

.queue-wrapper-content {
  &.queue-service-type-wrapper {
    
    .service-type-radio-wrapper {
      width: calc(100% - 38px);
      padding: 19px;

      .display-reason {
        padding-left: 48px;
        font-size: 14px;
        color: #7b7b7b;
      }
    }
    
    .service-type-radio {
      @include display-flex(normal, center);
      // line-height: 18px;
      cursor: pointer;
      input[type="radio"] {
        flex-shrink: 0;
      }
      .image {
        width: 18px;
        height: 24px;
        margin: 0 9px;
      }
      label {
        white-space: pre-line;
      }

      &.disable {
        color: #7b7b7b;
        cursor: default;
        input[type="radio"] {
          border: 2px solid #7b7b7b;
          cursor: default;
        }
        label {
          cursor: default;
        }
        .disabled-label {
          flex-shrink: 0;
          margin-left: auto;
        }
      }
    }
  }
}

@media only screen and (min-width: $desktopMinWidth) {
  .queue-wrapper-content {
    &.queue-service-type-wrapper {

      .service-type-radio-wrapper {
        .display-reason {
          padding-left: 70px;
          font-size: 26px;
        }
      }

      .service-type-radio {
        font-size: 36px;
        .image {
          width: 30px;
          height: 36px;
          margin: 0 15px;
        }
        input[type="radio"] {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}
