@import "./global-variables.scss";

.banner-list {
  @include display-flex(flex-start, center);
  width: 90%;
  overflow-x: auto;
  margin: 0px auto 30px auto;
  .detail-image {
    width: 100%;
    margin: 0 auto;
    flex-shrink: 0;
    &:not(:only-child) {
      width: 90%;
    }
  }
}
