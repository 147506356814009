@import "./global-variables.scss";

.queue-wrapper-content {
  &.queue-time-section-wrapper {
    .time-section-radio-wrapper {
      @include display-flex(normal, center);
      width: calc(100% - 38px);
      padding: 19px;
      line-height: 18px;
      cursor: pointer;
      input[type="radio"] {
        flex-shrink: 0;
      }
      &.disable {
        color: #7b7b7b;
        cursor: initial;
        input[type="radio"] {
          border: 2px solid #7b7b7b;
        }
        .disabled-label {
          flex-shrink: 0;
          margin-left: auto;
        }
      }
    }
  }
}

@media only screen and (min-width: $desktopMinWidth) {
  .queue-wrapper-content {
    &.queue-time-section-wrapper {
      .time-section-radio-wrapper {
        font-size: 36px;
        input[type="radio"] {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}
