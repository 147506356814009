@import "./global-variables.scss";
// Base TV css

#root .main-content {
  // background-color: #fff;
  // background: #ECECEC;

  th{
    font-weight: inherit;
  }
}

.main-content {

  .tv-detail-wrapper {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    font-family: 'Noto Serif TC', serif;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    .en {
      font-family: 'Noto Sans', sans-serif;
    }

    // -webkit-overflow-scrolling: touch;
    .reject-reason {
      @include display-flex(center, center);
      margin: 30px;
      font-weight: 700;
      font-size: 24px;
      color: #00ff38;
    }

    .ticket-type-title-wrapper {
      background-color: #BDBDBD;
      display: grid;
      grid-template-columns: min-content auto min-content;
      // justify-content: space-between;
      align-items: center;
      gap: 36px;
      grid-template-areas: "A B C";
      font-size: clamp(50px, 4vw, 64px);
      padding: 19px 30px;
      font-weight: 600;

      &.no-direction{
        gap: 0px;
      }

      .left {
        grid-area: A;
        width: clamp(50px, 13vw, 120px);
      }

      .ticket-title-and-icon {
        display: flex;
        align-items: center;
        grid-area: B;

        img {
          width: clamp(80px, 8vw, 120px);
          margin-right: 36px;

          &.right-table-type-icon {
            margin-right: 0px;
            margin-left: 36px;
          }
        }
      }
    
      .title {
        // text-align: center;
        line-height: 90%;

        .en {
          font-size: clamp(34px, 3vw, 44px);
          font-weight: 500;
          font-family: 'Noto Sans', sans-serif;
        }
      }
    
      .right {
        grid-area: C;
        width: clamp(50px, 13vw, 120px);
      }
    }

    .background-site-info {
      background-color: #fff;
    }

    table, th, tr, td {
      border: none ;
    }

    .tv-table {
      text-align: left;
      color: black;
      width: 100%;
      border-collapse: collapse;
      border: none ;

      .tv-data-body td{
        // padding-top: 30px;
        // padding-bottom: 30px;
        padding: 14px;
        // border-top: 2px solid red;
        font-size: clamp(55px, 4vw, 100px);
        font-weight: 700;
        text-align: center;
      }

      .tv-data-body td:first-child{
        padding-left: 30px;
        width: 10%;
      }

      .tv-data-body td:last-child{
        // padding-right: 30px;
      }

      .tv-table-type-data {
        width: 15%;
      }

      .tv-queue-calling-display {
        width: 25%;
      }

      .tv-table-title {
        font-size: clamp(32px, 2.5vw, 42px);
        font-weight: 500;
        line-height: 90%;
        .en {
          font-size: clamp(12px, 1.5vw, 20px);
        }

        th {
          padding-top: 10px;
          text-align: center;
        }
      }

      .tv-table-type-square img{
        border-radius: 4px;
        font-size: clamp(52px, 4vw, 72px);
        font-weight: 700;
        // width: clamp(88px, 5vw, 98px);
        height: clamp(88px, 5vw, 98px);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .tv-tableType-label {
        display: flex;
        align-items: center;
        justify-content: center;
        // gap: 30px;
        font-weight: 600;
        // padding-right: 30px;

        .tv-table-type-square {
          flex-shrink: 0;
          margin-right: 30px;
        }

        .tv-table-type-label-content {
          text-align: left;
        }

        .tc-label {
          font-size: clamp(40px, 2.5vw, 50px);
        }

        .en-label {
          font-size: clamp(20px, 2vw, 30px);
          font-weight: 500;
          font-family: 'Noto Sans', sans-serif;
        }
      }

      .tv-tag-calling {
        border-radius: 4px;
        color: white;
        padding: 0px 18px;
        height: clamp(88px, 5vw, 98px);
        font-size: 58px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        position: relative;
        // width: fit-content;
      }

      .reverse-tag-for-blink {
        position: absolute;
        width: calc(100% - 36px);
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        animation: blink-animation 1s step-end 4;
        -webkit-animation: blink-animation 1s step-end 4;
      }

    }

    .border-A {
      border: 10px solid $tableTypeAColor;
    }

    .border-B {
      border: 10px solid $tableTypeBColor;
    }

    .border-C {
      border: 10px solid $tableTypeCColor;
    }

    .border-D {
      border: 10px solid $tableTypeDColor;
    }

    .border-E {
      border: 10px solid $tableTypeEColor;
    }

    .border-F {
      border: 10px solid $tableTypeFColor;
    }

    .tv-tag-bottom-part {
      // border: 10px solid #000000;
      padding: 20px;
      background: black;

      .time {
        font-family: Wallpoet;
        font-weight: normal;
        font-size: 28px;
      }

      .zig-zag {
        background-image: linear-gradient(-45deg, #ffffff 14px, transparent 0), linear-gradient(45deg, #ffffff 14px, transparent 0);
        height: 5px;
        background-size: 5px 20px;

      }

      .zig-zag-bottom {
        background-image: linear-gradient(-135deg, #ffffff 14px, transparent 0), linear-gradient(135deg, #ffffff 14px, transparent 0);
        height: 5px;
        background-size: 5px 20px;
        background-position: 10px -15px;
      }

      // .zig-zag-bottom {
      //   &.zig-zag-F {
      //     @include zig-zag-bottom(#000000);
      //   }
      // }
      .ticket-sequence {
        font-size: 20px;

        .ticket {
          padding: 10px;
          background-color: #fff;
        }
      }
    }

    .tv-tag-detail {
      display: flex;
      justify-content: space-between;
    }

    .tv-tag-sequence-list {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .tv-table-type-label {
      padding: 20px;
      text-align: center;
      font-weight: 700;
      font-size: 30px;
    }

    .ticket-number {
      text-align: center;
      font-size: 50px;
    }

  }


}

@media only screen and (min-width: $desktopMinWidth) {
  html body #root .main-content {
    // width: 100%;
    // height: 100%;
    // padding: 0px;
    // margin: 0px;
    // max-width: 100%;
    // border: 0px;
    // border-radius: 0px;

    div::-webkit-scrollbar {
      width: 0px !important;
    }
  }
}

@keyframes blink-animation {
  50% {
    opacity: 1;
  }
}
@-webkit-keyframes blink-animation {
  50% {
    opacity: 1;
  }
}