@import './global-variables.scss';

.reservation-wrapper {

  .reject-reason {
    @include display-flex(center, center);
    margin: 30px;
    font-weight: 700;
    font-size: 24px;
    color: #00ff38;
  }
  
  .reservation-date-wrapper {
    .table-size-wrapper,
    .select-date-wrapper,
    .surname-wrapper,
    .input-text-wrapper,
    .drop-down-text-wrapper,
    .email-text-wrapper {
      width: calc(80% - 2px);
      margin: 0px auto;
    }
    .table-size-wrapper {
      .options {
        width: calc(80% - 2px);
        margin: 0px auto;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        column-gap: 10px;
        row-gap: 10px;
        .option {
          border: 2px solid rgba(255, 255, 255, 0.2);
          border-radius: 10px;
          padding: 5px 10px;
          text-align: center;
          cursor: pointer;
          &.disable {
            background-color: rgba(118, 118, 118, 0.3);
            opacity: 0.38;
            cursor: initial;
          }
          &.selected {
            background-color: #ffffff;
            color: #000000;
          }
        }
      }
    }
    .select-date-message {
      opacity: 0.38;
      font-size: 14px;
    }
    .survey-content {
      width: calc(80% - 2px);
      margin: 0px auto;
      .survey-description {
        margin-bottom: 10px;
      }
      .question {
        width: 100%;
        margin-bottom: 10px;
        .question-title {
          margin-bottom: 5px;
          display: flex;
          align-items: center;
          .reset-button {
            display: flex;
            align-items: center;
            margin-left: 10px;
            padding: 5px 5px;
            border-radius: 7px;
            background-color: rgba(255, 255, 255, 0.3);
            .reset-icon {
              width: 12px;
            }
          }

          .reset-button:hover {
            cursor: pointer;
            opacity: 0.8;
          }

          .reset-button:active {
            opacity: 0.9;
          }
        }
      }
      .select-wrapper,
      .input-text-wrapper,
      .drop-down-text-wrapper {
        width: 100%;
      }
    }
  }
}

@media only screen and (min-width: $desktopMinWidth)  {
  // .main-content {
  //   .reservation-bottom-btn-wrapper {
  //     border-bottom-left-radius: 10px;
  //     border-bottom-right-radius: 10px;
  //     position: absolute;
  //     left: 0px;
  //     bottom: 0px;
  //     width: calc(100% - 52px);
  //     padding: 20px 26px;
  //     .reservation-button {
  //       height: 50px;
  //       max-width: 240px;
  //       .arrow {
  //         width: 30px;
  //       }
  //     }
  //   }
  // }
}