@import "./global-variables.scss";

.error-wrapper {
  width: 100%;
  height: calc(100% - $queueContentBottomPadding);
  padding-bottom: $queueContentBottomPadding;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  .message {
    padding-top: 50px;
    text-align: center;
  }
}

@media only screen and (min-width: $desktopMinWidth) {
  .error-wrapper {
    font-size: 36px;
  }
}