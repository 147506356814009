@import './global-variables.scss';

.main-content {
  .reservation-wrapper {
    width: 100%;
    // height: calc(100% - $queueContentBottomPadding);
    // min-height: 90vh;
    // padding-bottom: $queueContentBottomPadding;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    flex: 1;
    display: flex;
    flex-direction: column;

    .reservation-wrapper-content {
      flex: 1;
    }
  }
  .reservation-bottom-btn-wrapper {
    @include display-flex(space-between, center);
    // position: fixed;
    // bottom: 0px;
    width: calc(100% - 52px);
    padding: 20px 26px;
    // left: 0;
    color: black;
    background-color: black;
    .reservation-button {
      @include display-flex(center, center);
      width: 40%;
      height: 35px;
      background: #FFFFFF;
      border-radius: 5px;
      cursor: pointer;
      &.disable {
        opacity: 0.38;
        cursor: initial;
      }
      &.previous {
        .arrow {
          transform: rotate(180deg);
        }
        
        &:only-child {
          margin-right: auto;
        }
      }
      &.next {
        &:only-child {
          margin-left: auto;
        }
      }
      .arrow {
        width: 20px;
      }
    }
  }
  .reservation-single-button-wrapper {
    @include display-flex(center, center);
    width: 100%;
    height: 100%;
    min-height: inherit;
    .single-button {
      @include display-flex(center, center);
      width: fit-content;
      height: 35px;
      padding: 0px 44px;
      margin: 0px auto;
      border-radius: 5px;
      background-color: #ffffff;
      color: #000000;
      cursor: pointer;
      &.disable {
        background: #7b7b7b;
        cursor: initial;
      }
    }
  }
}

@media only screen and (min-width: $desktopMinWidth)  {
  .main-content {
    .reservation-wrapper {
      height: calc(100% - $desktopQueueContentBottomPadding);
      padding-bottom: 0px;
      margin-bottom: $desktopQueueContentBottomPadding;
      &:not(.ticket-wrapper) {
        &>.gulu-logo-wrapper {
          display: none;
        }
      }
      .reservation-wrapper-content {
        @include display-flex(flex-start, flex-start, column);
        width: 100%;
        max-width: 660px;
        margin: 0px auto;
        .queue-title {
          @include display-flex(center, center);
        }
      }
    }
    .reservation-bottom-btn-wrapper {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      position: absolute;
      left: 0px;
      bottom: 0px;
      width: calc(100% - 52px);
      padding: 20px 26px;
      .reservation-button {
        height: 50px;
        max-width: 240px;
        .arrow {
          width: 30px;
        }
      }
    }
  }
}