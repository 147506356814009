@import "./global-variables.scss";

.queue-wrapper-content {
  &.queue-personal-info-wrapper {
    .personal-info-wrapper {
      @include display-flex(normal, center, column);
      width: 100%;
      line-height: 18px;
      margin-bottom: 20px;
      .input-text-wrapper,
      .drop-down-text-wrapper {
        width: calc(100% - 40px);
        margin: 0px 20px;
      }
      .error {
        border: 2px solid #ff0000 !important;
      }
    }
  }
}

@media only screen and (min-width: $desktopMinWidth) {
  // .queue-wrapper-content {
  //   &.queue-personal-info-wrapper {
      // .personal-info-wrapper {
      //   width: 100%;
      //   .email-wrapper {
      //     .email {
      //       height: 80px;
      //     }
      //   }
      //   .drop-down-text-content {
      //     .drop-down-text-wrapper {
      //       width: 100%;
      //       height: 80px;
      //       .drop-down-wrapper {
      //         .drop-down-select {
      //           font-size: 28px;
      //         }
      //       }
      //     }
      //   }
      // }
    // }
  // }
}
