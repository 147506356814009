@import "./global-variables.scss";

.site-detail-wrapper {
  width: 100%;
  height: calc(100% - $queueContentBottomPadding);
  padding-bottom: $queueContentBottomPadding;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  display: flex;
  flex-direction: column;
  flex: 1;

  .reject-reason {
    @include display-flex(center, center);
    margin: 30px;
    font-weight: 700;
    font-size: 24px;
    color: #00ff38;
  }
  .tag-sequence-list-wrapper {
    padding: 15px 18px 10px 18px;
    margin-top: 10px;
    position: relative;
    flex: 1;
    .current-date {
      width: 100%;
      margin-bottom: 15px;
      font-weight: 400;
      font-size: 18px;
      text-align: center;
      color: #c4c4c4;
    }
    .title {
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: 700;
    }
    .tag-sequence,
    .counter-item {
      width: calc(100% - 28px);
      margin: 0px 8px 10px 8px;
    }
    .expand-button {
      @include display-flex(center, center);
      width: calc(100% - 36px);
      height: 70px;
      position: absolute;
      bottom: 0px;
      font-size: 18px;
      font-weight: 700;
      background-color: #141414;
      cursor: pointer;
      &.collapse {
        width: 100%;
        position: initial;
      }
      .drop-down-image {
        width: 14px;
        height: 7px;
        margin-right: 10px;
        &.change {
          -ms-transform: rotate(180deg); /* IE 9 */
          transform: rotate(180deg);
        }
      }
    }
  }
  .navigation-list {
    position: relative;
    .navigation-button {
      @include display-flex(center, center);
      width: calc(100% - 106px);
      padding: 20px;
      margin: 15px 30px 15px 30px;
      border: 3px solid #ffffff;
      border-radius: 5px;
      font-size: 36px;
      font-weight: bold;
      color: #ffffff;
      cursor: pointer;
      &:not(:last-child) {
        margin-bottom: 30px;
      }
      &.pre-reg {
        background-color: #087f8c;
        .image {
          &.pre-reg {
            width: 58px;
            height: 58px;
            margin-right: 30px;
          }
          &.qrcode {
            width: 46px;
            height: 57px;
            margin-right: 12px;
          }
        }
      }
      &.request-ticket {
        background-color: #5aaa95;
        .image {
          width: 34px;
          height: 34px;
          margin-right: 12px;
        }
      }
      &.disable {
        opacity: 0.5;
        cursor: initial;
        filter: grayscale(1);
      }
    }
  }
  .detail-wrapper {
    .ticket-title {
      @include display-flex(flex-start, flex-start);
      color: #ffffff;
      font-weight: bold;
      font-size: 14px;
      margin: 9px auto 2px auto;
      width: 90%;
    }
  }
}

.background-site-info-body {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.bottom-btn-wrapper {
  @include display-flex(center, center);
  position: fixed;
  bottom: 0;
  width: calc(100% - 52px);
  padding: 20px 26px;
  background-color: black;
  .queue-button {
    @include display-flex(center, center);
    height: 35px;
    padding: 0px 44px;
    border-radius: 5px;
    background-color: #ffffff;
    color: #000000;
    cursor: pointer;
    &.disable {
      background: #7b7b7b;
      cursor: initial;
    }
  }
}

@media only screen and (min-width: $desktopMinWidth) {
  .main-content {
    &.tv {
      .site-detail-wrapper {
        height: 100%;
        margin-bottom: 0px;
      }
    }
  }
  .site-detail-wrapper {
    height: calc(100% - $desktopQueueContentBottomPadding);
    padding-bottom: 0px;
    margin-bottom: $desktopQueueContentBottomPadding;
    .header-wrapper {
      & > .gulu-logo-wrapper {
        // display: none;
      }
    }
    .reject-reason {
      font-size: 45px;
    }
    .tag-sequence-list-wrapper {
      &.background-site-info {
        background-color: initial;
      }
      .current-date {
        font-size: 38px;
      }
      .title {
        margin-left: -18px;
        font-size: 38px;
      }
      .tag-sequence-list,
      .counter-tag-list,
      .counter-list {
        @include display-flex(center, flex-start);
        flex-wrap: wrap;
        .tag-sequence,
        .counter-item {
          width: calc(50% - 55px);
          flex-shrink: 0;
          &:nth-child(odd) {
            margin: 0px 25px 15px 0px;
          }
          &:nth-child(even) {
            margin: 0px 0px 15px 25px;
          }
          &:only-child {
            margin: 0px 0px 15px 0px;
          }
        }
      }
      .expand-button {
        font-size: 30px;
        .drop-down-image {
          width: 22px;
          height: 11px;
        }
      }
    }
    .banner-list {
      max-width: 672px;
      margin: 0px auto 30px auto;
    }
    .navigation-list {
      width: 380px;
      margin: 0px auto;
    }
  }
  .bottom-btn-wrapper {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: absolute;
    left: 0px;
    bottom: 0px;
    .queue-button {
      height: 50px;
      font-size: 24px;
      font-weight: bold;
    }
  }
}
