@import "./global-variables.scss";

.calendar {
  width: 100%;
  font-size: 12px;
  .header {
    @include display-flex(normal, center);
    width: calc(100% - 20px);
    height: 40px;
    padding: 0px 10px;
    background: #303030;
    color: #ffffff;
    font-weight: bold;
    text-align: center;
    .button-wrapper {
      font-size: 16px;
      font-weight: normal;
      width: 40px;
      height: 100%;
      .prev-month,
      .next-month {
        @include display-flex(center, center);
        width: 40px;
        height: 100%;
        cursor: pointer;
        .arrow {
          &.prev {
            -ms-transform: rotate(90deg); /* IE 9 */
            transform: rotate(90deg);
          }
          &.next {
            -ms-transform: rotate(-90deg); /* IE 9 */
            transform: rotate(-90deg);
          }
        }
      }
    }
    .title {
      flex: 1;
      text-align: center;
    }
    .icon {
      width: 20px;
      height: 20px;
    }
  }
  // .weekdays-background {
  //   background: #303030;
  //   height: 28px;
  //   width: 100%;
  //   position: absolute;
  //   z-index: -1;
  //   max-width: 375px;
  // }
  .calendar-content {
    width: 80%;
    margin: auto;
    text-align: center;
    border-collapse: collapse;
    table-layout: fixed;
    .weekdays {
      td {
        padding: 5px 0px;
      }
    }
    tbody {
      font-size: 14px;
      font-family: poppins;
      line-height: 14px;
      td {
        .date-wrapper {
          @include display-flex(center, center);
          height: 28px;
          margin: 5px 0px 5px 0px;
          border-radius: 5px;
          color: white;
          user-select: none;
          -moz-user-select: none;
          -khtml-user-select: none;
          -webkit-user-select: none;
          -o-user-select: none;
          &:not(.disable) {
            cursor: pointer;
          }
          &.selected {
            background-color: #ffffff;
            color: #000000;
            width: 28px;
            height: 28px;
            margin: auto;
            border-radius: 50%;
            margin: 5px auto 5px auto;
          }
          &.disable {
            color: #3c3844;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: $desktopMinWidth) {
  .calendar {
    width: 440px;
    padding: 30px;
    margin: 0px auto;
    border-radius: 5px;
    .header {
      width: calc(100% - 60px);
      margin: 0px 20px;
      font-size: 20px;
      .button-wrapper {
        font-size: 20px;
      }
    }
    .weekdays-background {
      display: none;
    }
    .calendar-content {
      width: 90%;
      .weekdays {
        font-size: 18px;
      }
      tbody {
        td {
          .date-wrapper {
            height: 40px;
            font-size: 20px;
            &.selected {
              width: 40px;
              height: 40px;
            }
          }
        }
      }
    }
  }
}
