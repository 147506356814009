@import "./global-variables.scss";

body {
  background-color: #ffffff;
  #root {
    width: 100%;
    height: 100%;
  }
}

.main-content {
  background-color: $backgroundColor;
  color: #ffffff;
  min-height: 100vh;
  margin: auto;

  display: flex;
  flex-direction: column;

  input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    border: 2px solid white;
    margin-right: 16px;
    cursor: pointer;
  }

  input[type="radio"]:checked {
    box-shadow: inset 0 0 0 2px black;
    background: $selectedColor;
    background-clip: content-box;
  }

  input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 22px;
    height: 22px;
    margin-right: 16px;
    background: #333333;
    border: 1.5px solid #333333;
    top: 2px;
    margin: 0px;
    border-radius: 20%;
    cursor: pointer;
  }

  input[type="checkbox"]:checked {
    width: 6px;
    height: 12px;
    border: solid $selectedColor;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    margin: 0px 8px;
  }
  .select-wrapper {
    @include display-flex(center, center);
    width: calc(80% - 2px);
    height: 38px;
    margin: 0px auto;
    position: relative;
    &.disabled {
      .image {
        fill: rgba(118, 118, 118, 0.3);
      }
    }
    .picker {
      padding-right: 0px;
      border: 2px solid rgba(255, 255, 255, 0.2);
      flex: 1;
      height: 100%;
      border-radius: 12px;
      text-align: center;
      font-size: 16px;
      background-color: rgba(255, 255, 255, 0.2);
      color: #ffffff;
      cursor: pointer;
      &:disabled {
        border: 2px solid rgba(118, 118, 118, 0.3);
        color: rgba(118, 118, 118, 0.8);
      }
    }
    .picker option {
      text-align: center;
      color: #000000;
      background-color: #ffffff;
      cursor: pointer;
    }
    .picker:focus option:checked {
      background: #e6e6e6;
    }
    .picker:focus option:hover {
      background: #e6e6e6;
    }
    .image {
      width: 15px;
      height: 7.5px;
      align-self: center;
      fill: #ffffff;
      position: absolute;
      right: 15px;
      pointer-events: none;
    }
  }
  .input-text-wrapper {
    width: 100%;
    .text-limit {
      margin-bottom: 5px;
      font-size: 12px;
      color: $disableColor;
    }
    .input-text {
      width: calc(100% - 40px);
      margin: 0px auto;
      padding: 9px 18px;
      background: rgba(255, 255, 255, 0.2);
      border: 2px solid rgba(255, 255, 255, 0.2);
      // box-sizing: border-box;
      border-radius: 12px;
      color: #ffffff;
      font-size: 16px;
      // &:last-child {
      //   margin: 0px auto 20px auto;
      // }
    }
  }

  input:focus,
  select:focus {
    outline: none;
  }

  .logo-blink {
    animation: logo-blink 2s steps(2, start) reverse infinite;
  }
  img {
    display: block;
    padding: 0px;
    margin: 0px;
  }
  .surname-wrapper {
    @include display-flex(normal, normal, column);
    width: 100%;
    .surname {
      @include display-flex(normal, normal);
      width: calc(100% - 4px);
      height: 36px;
      margin: 0px auto;
      background: rgba(255, 255, 255, 0.2);
      border: 2px solid rgba(255, 255, 255, 0.2);
      // box-sizing: border-box;
      border-radius: 12px;
      font-size: 16px;
      &:last-child {
        margin: 0px auto 10px auto;
      }
      .name-title-wrapper {
        @include display-flex(flex-start, center);
        width: 25%;
        height: 100%;
        .name-title-select {
          width: 100%;
          height: 100%;
          padding-right: 0px;
          background: rgba(255, 255, 255, 0);
          border: 2px solid rgba(255, 255, 255, 0);
          border-radius: 12px;
          color: #ffffff;
          text-align: center;
          cursor: pointer;
        }
        .name-title-select option {
          color: #000000;
          background-color: #ffffff;
        }
        .name-title-select:focus option:checked {
          background: #e6e6e6;
        }
        .name-title-select:focus option:hover {
          background: #e6e6e6;
        }
        .image {
          width: 10px;
          height: 5px;
          margin-left: -15px;
          align-self: center;
          fill: #ffffff;
        }
      }
      .surname-input {
        width: 75%;
        height: 100%;
        background: transparent;
        border: 0px;
        border-radius: 12px;
        margin: 0px;
        font-size: 16px;
        color: #ffffff;
      }
    }
  }
  .email-text-wrapper {
    width: 100%;
    .email-text {
      @include display-flex(normal, center);
      width: calc(100% - 4px);
      height: 36px;
      margin: 0px auto;
      background: rgba(255, 255, 255, 0.2);
      border: 2px solid rgba(255, 255, 255, 0.2);
      border-radius: 12px;
      color: #ffffff;
      font-size: 16px;
      &:last-child {
        margin: 0px auto 10px auto;
      }
      .input-text {
        height: 100%;
        flex: 1;
        padding-left: 5px;
        border: 0px;
        border-radius: 12px;
        margin: 0px;
        font-size: 16px;
        background: transparent;
        color: #ffffff;
      }
      .subscribe-arrow {
        width: 23px;
        height: 20px;
        margin-right: 5px;
        path {
          stroke: #ffffff;
        }
      }
    }
  }
  .drop-down-text-wrapper {
    @include display-flex(normal, normal, column);
    width: 100%;
    .drop-down-text,
    .otp-wrapper  {
      @include display-flex(normal, center);
      width: calc(100% - 4px);
      height: 36px;
      margin: 0px auto;
      background: rgba(255, 255, 255, 0.2);
      border: 2px solid rgba(255, 255, 255, 0.2);
      // box-sizing: border-box;
      border-radius: 12px;
      font-size: 16px;
    }
    .drop-down-text {
      margin: 0px auto 10px auto;
      &.error {
        margin: 0px auto;
      }
      .drop-down-wrapper {
        @include display-flex(flex-start, center);
        width: 25%;
        height: 100%;
        .drop-down-select {
          width: 100%;
          height: 100%;
          padding-right: 0px;
          background: rgba(255, 255, 255, 0);
          border: 2px solid rgba(255, 255, 255, 0);
          border-radius: 12px;
          color: #ffffff;
          text-align: center;
          cursor: pointer;
        }
        .drop-down-select option {
          color: #000000;
          background-color: #ffffff;
        }
        .drop-down-select:focus option:checked {
          background: #e6e6e6;
        }
        .drop-down-select:focus option:hover {
          background: #e6e6e6;
        }
        .image {
          width: 10px;
          height: 5px;
          margin-left: -15px;
          align-self: center;
          fill: #ffffff;
        }
      }
      .text-input {
        flex: 1;
      }
      // .text-input {
      //   width: 75%;
      //   height: 100%;
      //   background: transparent;
      //   border: 0px;
      //   border-radius: 12px;
      //   margin: 0px;
      //   font-size: 16px;
      //   color: #ffffff;
      // }
      .subscribe-arrow {
        width: 23px;
        height: 20px;
        margin-right: 5px;
        path {
          stroke: #ffffff;
        }
      }
      .send-otp-button {
        @include display-flex(center, center);
        height: 100%;
        border-radius: 10px;
        font-size: 12px;
        padding: 0px 10px;
        background-color: #ffffff;
        color: #000000;
        &.disable {
          opacity: 0.38;
          cursor: initial;
        }
        .countdown-image-wrapper {
          position: relative;
          .number {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -46%);
          }
        }
        .resend-wrapper {
          @include display-flex(center, center);
          svg {
            width: 26px;
          }
        }
        .check-icon {
          color: $selectedColor;
        }
      }
    }
    .otp-wrapper {
      margin: 0px auto;
      &.disable {
        opacity: 0.38;
        cursor: initial;
      }
      &:last-child {
        margin: 0px auto 10px auto;
      }
      .otp-prefix {
        width: 25%;
        text-align: center;
      }
    }
    .text-input {
      width: 75%;
      height: 100%;
      background: transparent;
      border: 0px;
      border-radius: 12px;
      margin: 0px;
      font-size: 16px;
      color: #ffffff;
    }
  }
  .select-time-wrapper {
    width: calc(80% - 2px);
    margin: 0px auto;
    .select-time-message {
      opacity: 0.38;
      font-size: 14px;
    }
    .group-description {
      margin: 5px 0px;
    }
    .options {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      column-gap: 10px;
      row-gap: 10px;
      .option {
        border: 2px solid rgba(255, 255, 255, 0.2);
        border-radius: 10px;
        padding: 5px 10px;
        text-align: center;
        cursor: pointer;
        &.disable {
          background-color: rgba(118, 118, 118, 0.3);
          opacity: 0.38;
          cursor: initial;
        }
        &.selected {
          background-color: #ffffff;
          color: #000000;
        }
      }
    }
  }
  .radio-list-wrapper {
    width: 100%;
    .radio-wrapper {
      @include display-flex(flex-start, center);
      width: 100%;
      margin-bottom: 5px;
    }
  }
  .checkbox-list-wrapper {
    width: 100%;
    .choice-range {
      margin-bottom: 5px;
      font-size: 12px;
      color: $disableColor;
    }
    .checkbox-wrapper {
      @include display-flex(flex-start, center);
      width: 100%;
      margin-bottom: 5px;
      .checkbox {
        @include display-flex(center, center);
        width: 16px;
        height: 16px;
        border: 1px solid #ffffff;
        border-radius: 3px;
        margin-right: 10px;
        .check-icon {
          width: 12px;
          height: 12px;
          color: #ffffff;
        }
      }
    }
  }
  .rating-wrapper {
    .image {
      margin-right: 5px;
      cursor: pointer;
    }
  }
}

.error-message {
  @include display-flex(flex-start, center);
  height: 20px;
  color: #ff0000;
  font-size: 12px;
}

select {
  -webkit-appearance: none;
  border: 0;
  padding-right: 30px;
}

label {
  cursor: pointer;
}

.text-A {
  color: $tableTypeAColor;
}
.text-B {
  color: $tableTypeBColor;
}
.text-C {
  color: $tableTypeCColor;
}
.text-D {
  color: $tableTypeDColor;
}
.text-E {
  color: $tableTypeEColor;
}
.text-F {
  color: $tableTypeFColor;
}
.text-R {
  color: $tableTypeRColor;
}

.background-A {
  background-color: $tableTypeAColor;
}
.background-B {
  background-color: $tableTypeBColor;
}
.background-C {
  background-color: $tableTypeCColor;
}
.background-D {
  background-color: $tableTypeDColor;
}
.background-E {
  background-color: $tableTypeEColor;
}
.background-F {
  background-color: $tableTypeFColor;
}
.background-R {
  background-color: $tableTypeRColor;
}

.background-dark-A {
  background-color: $tableTypeADarkColor;
}
.background-dark-B {
  background-color: $tableTypeBDarkColor;
}
.background-dark-C {
  background-color: $tableTypeCDarkColor;
}
.background-dark-D {
  background-color: $tableTypeDDarkColor;
}
.background-dark-E {
  background-color: $tableTypeEDarkColor;
}
.background-dark-F {
  background-color: $tableTypeFDarkColor;
}
.background-dark-R {
  background-color: $tableTypeRDarkColor;
}
.background-site-info {
  background-color: #141414;
}
.background-transparent {
  background-color: transparent;
}

.logo-white path {
  fill: #ffffff !important;
  &.path-with-stroke {
    stroke: #ffffff !important;
    fill: inherit  !important;
  }
}

.logo-A path {
  fill: $tableTypeAColor !important;
  &.path-with-stroke {
    stroke: $tableTypeAColor !important;
    fill: inherit  !important;
  }
}
.logo-B path {
  fill: $tableTypeBColor !important;
  &.path-with-stroke {
    stroke: $tableTypeBColor !important;
    fill: inherit !important;
  }
}
.logo-C path {
  fill: $tableTypeCColor !important;
  &.path-with-stroke {
    stroke: $tableTypeCColor !important;
    fill: inherit  !important;
  }
}
.logo-D path {
  fill: $tableTypeDColor !important;
  &.path-with-stroke {
    stroke: $tableTypeDColor !important;
    fill: inherit  !important;
  }
}
.logo-E path {
  fill: $tableTypeEColor !important;
  &.path-with-stroke {
    stroke: $tableTypeEColor !important;
    fill: inherit  !important;
  }
}
.logo-F path {
  fill: $tableTypeFColor !important;
  &.path-with-stroke {
    stroke: $tableTypeFColor !important;
    fill: inherit  !important;
  }
}
.logo-R path {
  fill: $tableTypeRColor !important;
  &.path-with-stroke {
    stroke: $tableTypeRColor !important;
    fill: inherit  !important;
  }
}

@keyframes logo-blink {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes logo-blink {
  to {
    visibility: hidden;
  }
}

@media only screen and (min-width: $desktopMinWidth) {
  body {
    width: 100vw;
    height: 100vh;
    #root {
      @include display-flex(center, center);
      .main-content {
        width: calc(86% - 76px);
        height: calc(100% - 38px);
        padding: 38px 38px 0px 38px;
        // margin: 38px 0px;
        // border: 1px solid var(--themeBorderColor);
        max-width: 1300px;
        // border-radius: 10px;
        min-height: initial;
        position: relative;
        overflow-y: hidden;
      }
      div {
        &::-webkit-scrollbar {
          width: 14px;
        }
        &::-webkit-scrollbar-thumb {
          border: 4px solid rgba(0, 0, 0, 0);
          background-clip: padding-box;
          border-radius: 9999px;
          background-color: #ffffff;
        }
      }
      // input[type="number"],
      // input[type="text"] {
      //   font-size: 28px;
      // }
    }
  }
  // .error-message {
  //   font-size: 24px;
  // }
}
