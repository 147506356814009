@import './global-variables.scss';

.queue-bottom-btn-wrapper {
  @include display-flex(space-between, center);
  position: fixed;
  bottom: 0;
  width: calc(100% - 52px);
  padding: 20px 26px;
  color: black;
  background-color: black;
  // font-weight: 600;
  // font-size: 25px;
  .queue-button {
    @include display-flex(center, center);
    width: 40%;
    height: 35px;
    background: #FFFFFF;
    border-radius: 5px;
    cursor: pointer;
    &.disable {
      background: #7B7B7B;
      cursor: initial;
    }
    &.previous {
      .arrow {
        transform: rotate(180deg);
      }
      
      &:only-child {
        margin-right: auto;
      }
    }
    &.next {
      &:only-child {
        margin-left: auto;
      }
    }
    .arrow {
      width: 20px;
    }
  }
}

@media only screen and (min-width: $desktopMinWidth)  {
  .main-content {
    .queue-bottom-btn-wrapper {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      position: absolute;
      left: 0px;
      bottom: 0px;
      width: calc(100% - 52px);
      padding: 20px 26px;
      .queue-button {
        height: 50px;
        max-width: 240px;
        .arrow {
          width: 30px;
        }
      }
    }
  }
}