@import "../global-variables.scss";

.main-content.airport-taxi {
  // background-color: #fff;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  th{
    font-weight: inherit;
  }
}

.main-content.airport-taxi-rail::-webkit-scrollbar {
    width: 0px ;
}

.main-content.airport-taxi {
  // --hsrPrimaryColor: #0066A6;
  // --textColor: var(--hsrPrimaryColor);
  // --titleColorByTheme: #000000;

  .en {
    font-family: 'Noto Sans', sans-serif;
  }

  .tv-detail-wrapper {
    font-family: 'Noto Serif TC', serif;

    width: 100%;
    height: 100%;
    background: #ECECEC;
    overflow-x: hidden;
    overflow-y: scroll;
    // -webkit-overflow-scrolling: touch;
    .reject-reason {
      @include display-flex(center, center);
      margin: 30px;
      font-weight: 700;
      font-size: 24px;
      color: #00ff38;
    }

    .ticket-type-title-wrapper {
      display: grid;
      grid-template-columns: min-content auto min-content;
      // justify-content: space-between;
      align-items: center;
      gap: 36px;
      grid-template-areas: "A B C";
      font-size: clamp(50px, 4vw, 90px);
      padding: 19px 30px;
      font-weight: 600;
      min-height: 154px;

      .left {
        grid-area: A;
        width: clamp(80px, 8vw, 150px);
      }
    
      .title {
        grid-area: B;
        // text-align: center;
        line-height: 90%;

        .en {
          font-size: clamp(34px, 3vw, 70px);
          font-weight: 500;
          font-family: 'Noto Sans', sans-serif;
        }
      }
    
      .right {
        grid-area: C;
        width: clamp(80px, 8vw, 150px);
      }
    }

    .background-site-info {
      background-color: #fff;
    }

    .tv-table {
      text-align: left;
      color: black;
      width: 100%;
      border-collapse: collapse;

      .tv-data-body td{
        padding-top: 30px;
        padding-bottom: 30px;
        // border-top: 2px solid black;
      }

      .tv-data-body.with-bg-color {
        // background-color: #d8f0c9;
      }

      .tv-data-body td:first-child{
        padding-left: 30px;
        width: 10%;
      }

      .tv-data-body td:last-child{
        padding-right: 30px;
      }

      .tv-table-type-data {
        width: 10%;
      }

      .tv-queue-calling-display {
        width: 40%;
      }

      .tv-table-title.ticket-type-MAINLAND_BOUNDARY {
        background-color: #d8f0c9;
      }

      .tv-table-title.ticket-type-CROSS_BOUNDARY {
        background-color: #aeddf2;
      }

      .tv-table-title {
        font-size: clamp(32px, 2.5vw, 60px);
        font-weight: 500;
        line-height: 90%;
        .en {
          font-size: clamp(12px, 1.5vw, 40px);
        }

        .table-type {
          width: 10%;
        }

        th {
          padding-top: 10px;
          font-weight: 600;
          text-align: left;
          padding: 30px;
        }
      }

      .tv-table-type-square {
        border-radius: 4px;
        font-size: clamp(80px, 6vw, 130px);
        font-weight: 700;
        width: clamp(88px, 5vw, 130px);
        height: clamp(88px, 5vw, 130px);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
      }

      .tv-tableType-label {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        // gap: 30px;
        font-weight: 600;
        // padding-right: 30px;

        .tv-table-type-square {
          margin-right: 30px;
        }

        .tc-label {
          font-size: clamp(37px, 2.5vw, 70px);
        }

        .en-label {
          font-size: clamp(20px, 1.9vw, 50px);
          font-weight: 500;
          font-family: 'Noto Sans', sans-serif;
        }
      }

      .tv-tag-calling {
        border-radius: 4px;
        color: white;
        padding: 0px 0px;
        height: clamp(88px, 5vw, 130px);
        font-size: clamp(60px, 4vw, 110px);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: 700;
        position: relative;
        // width: fit-content;
      }

      .reverse-tag-for-blink {
        position: absolute;
        width: calc(100% - 36px);
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        animation: blink-animation 1s step-end 4;
        -webkit-animation: blink-animation 1s step-end 4;
      }

    }

    .border-A {
      border: 10px solid $tableTypeAColor;
    }

    .border-B {
      border: 10px solid $tableTypeBColor;
    }

    .border-C {
      border: 10px solid $tableTypeCColor;
    }

    .border-D {
      border: 10px solid $tableTypeDColor;
    }

    .border-E {
      border: 10px solid $tableTypeEColor;
    }

    .border-F {
      border: 10px solid $tableTypeFColor;
    }

    .tv-tag-bottom-part {
      // border: 10px solid #000000;
      padding: 20px;
      background: black;

      .time {
        font-family: Wallpoet;
        font-weight: normal;
        font-size: 28px;
      }

      .zig-zag {
        background-image: linear-gradient(-45deg, #ffffff 14px, transparent 0), linear-gradient(45deg, #ffffff 14px, transparent 0);
        height: 5px;
        background-size: 5px 20px;

      }

      .zig-zag-bottom {
        background-image: linear-gradient(-135deg, #ffffff 14px, transparent 0), linear-gradient(135deg, #ffffff 14px, transparent 0);
        height: 5px;
        background-size: 5px 20px;
        background-position: 10px -15px;
      }

      // .zig-zag-bottom {
      //   &.zig-zag-F {
      //     @include zig-zag-bottom(#000000);
      //   }
      // }
      .ticket-sequence {
        font-size: 20px;

        .ticket {
          padding: 10px;
          background-color: #fff;
        }
      }
    }

    .tv-tag-detail {
      display: flex;
      justify-content: space-between;
    }

    .tv-tag-sequence-list {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .tv-table-type-label {
      padding: 20px;
      text-align: center;
      font-weight: 700;
      font-size: 30px;
    }

    .ticket-number {
      text-align: center;
      font-size: 50px;
    }

  }


}

@media only screen and (min-width: $desktopMinWidth) {
  body #root .main-content.airport-taxi {
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
    max-width: 100%;
    border: 0px;
    border-radius: 0px;

    div::-webkit-scrollbar {
      width: 0px !important;
    }
  }
}

@keyframes blink-animation {
  50% {
    opacity: 1;
  }
}
@-webkit-keyframes blink-animation {
  50% {
    opacity: 1;
  }
}