@import "./global-variables.scss";

.queue-wrapper-content {
  &.queue-table-size-wrapper {
    .number-btn-wrapper {
      @include display-flex(normal, normal);
      flex-wrap: wrap;
      width: 82%;
      padding: 5% 0px;
      margin: 0px auto;
      .table-size-btn {
        @include display-flex(center, center);
        text-align: center;
        background: #171717;
        border-radius: 50%;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 50px;
        color: #ffffff;
        // margin: 9px;
        margin: 1.5%;
        width: 30%;
        height: 0;
        padding: 15% 0px;
        cursor: pointer;
        &.selected {
          background-color: $selectedColor;
        }
      }
    }
  }
}

@media only screen and (min-width: $desktopMinWidth) {
  .queue-wrapper-content {
    &.queue-table-size-wrapper {
      .number-btn-wrapper {
        width: 85%;
        .table-size-btn {
          width: 120px;
          padding: 60px 0px;
          margin: 0 auto 10px auto;
          font-size: 80px;
        }
      }
    }
  }
}
