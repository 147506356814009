@import './global-variables.scss';

.drop-down-wrapper{
  @include display-flex(flex-start, center);
  .drop-down-title-wrapper {
    @include display-flex(flex-start, center);
    padding: 5px 0px;
    flex: 1;
    cursor: pointer;
  }
  .dropdown-arrow{
    @include display-flex(space-between, center);
    text-align: center;
    margin-left: auto;
    .image{
      width: 10px;
      height: 5px;
    }
    .change{
      -ms-transform: rotate(180deg); /* IE 9 */
      transform: rotate(180deg);
    }
  }
}

.drop-down-content {
  color: $textColor;
  white-space: pre-line;
  font-size: 14px;
  width: 90%;
  margin: 0 auto 12px auto ;
}

.display-none{
  display: none;
}

@media only screen and (min-width: $desktopMinWidth)  {
  .drop-down-wrapper{
    @include display-flex(flex-start, center);
    .drop-down-title-wrapper {
      .drop-down-title {
        font-size: 28px;
      }
    }
    .dropdown-arrow{
      .image{
        width: 14px;
        height: 7px;
      }
    }
  }
  .drop-down-content {
    font-size: 24px;
  }
}