@import "./global-variables.scss";

.transferable-ticket-detail-wrapper {
  position: relative;
  color: white;

  .dividend-line {
    height: 8px;
    background: rgba(255, 255, 255, 0.10);
  }
}

.upper-line{
  height: 8px;
  width: 100%;
  background: rgba(255, 255, 255, 0.10);
}

.bottom-line{
  height: 8px;
  width: 100%;
  background: rgba(255, 255, 255, 0.10);
}


.calling-section-wrapper {

  display: flex;
  flex-direction: column;
  gap: 8px;

  .calling-item-wrapper.blink {
    animation: call-counter-animation 500ms ease-in-out alternate infinite;
    -webkit-animation: call-counter-animation 500ms ease-in-out alternate infinite;
  }

  .calling-item-wrapper {
    display: flex;
    align-items: stretch;
    background-color: #007300;
  
    .calling-icon {
  
      display: flex;
      align-items: center;
      padding: 8px 6px 8px 16px;
      background-color: #17C400;
  
      svg {
        width: 55px;
      }
  
    }

    .zig-zag {
      position: relative;
      width: 6px;
    }

    .zig-zag::after {
      content: "";
      position: absolute;
      background-repeat: repeat-y;
      background-position: -2px 6px;
      background-size: 20px 14px;
      background-image: linear-gradient(-44deg, transparent 14px,#17C400 15px),linear-gradient(44deg,#17C400 9px,transparent 10px);
      display: block;
      width: 12px;
      height: 100%;
      right: -6px;
      top: 0;
    }
  
    .calling-item-detail {

      padding: 7px 16px;

      .calling-item-counter {

        font-size: 22px;

        span {
          font-weight: 700;
        }
      }

      .calling-item-time-wrapper {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 14px;

        .dot {
          width: 4px;
          height: 4px;
          background-color: white;
          border-radius: 100%;
        }
      }

    }

  }

}

.waiting-section-wrapper {
  display: flex;
  padding: 32px 16px 16px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  .waiting-section-title {
    font-size: 14px;
    font-weight: 600;
  }

  .waiting-item {
    display: flex;
    width: 100%;
    position: relative;

    .waiting-detail {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

  }

    .arrow {
      flex: 1;
      display: flex;
      align-items: center;
      min-width: 100px;
    }

    .arrow-triangle {
      width: 5px;
      height: 11px;
      clip-path: polygon(100% 50%, 0 0, 0 100%);
    }

    .solid-arrow {

      .arrow-tail.A {
        background-color: $tableTypeAColor;
      }

      .arrow-tail.B {
        background-color: $tableTypeBColor;
      }

      .arrow-tail.C {
        background-color: $tableTypeCColor;
      }


      .arrow-tail.D {
        background-color: $tableTypeDColor;
      }
      
      .arrow-tail.E {
          background-color: $tableTypeEColor;
      }

      .arrow-tail.F {
        background-color: $tableTypeFColor;
      }


      .arrow-tail {
        flex: 1;
        height: 2px;
        position: relative;
        overflow: hidden;
      }

      -webkit-mask-image: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 44%, rgba(255,255,255,0) 60%, rgba(255,255,255,1) 74%, rgba(255,255,255,1) 100%);
          mask-image: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 44%, rgba(255,255,255,0) 60%, rgba(255,255,255,1) 74%, rgba(255,255,255,1) 100%);
      -webkit-mask-size: 200% 100%;
              mask-size: 200% 100%;
      animation: arrow-animation 2s linear infinite;
      



      .arrow-triangle.A {
        background-color: $tableTypeAColor;
      }

      .arrow-triangle.B {
        background-color: $tableTypeBColor;
      }

      .arrow-triangle.C {
        background-color: $tableTypeCColor;
      }

      .arrow-triangle.D {
        background-color: $tableTypeDColor;
      }

      .arrow-triangle.E {
        background-color: $tableTypeEColor;
      }

      .arrow-triangle.F {
        background-color: $tableTypeFColor;
      }


    }

    .waiting-time-section.A {
      color: $tableTypeAColor;
    }

    .waiting-time-section.B {
      color: $tableTypeBColor;
    }

    .waiting-time-section.C {
      color: $tableTypeCColor;
    }
    
    .waiting-time-section.D {
      color: $tableTypeDColor;
    }

    .waiting-time-section.E {
      color: $tableTypeEColor;
    }

    .waiting-time-section.F {
      color: $tableTypeFColor;
    }

    .waiting-time-section {
      display: flex;
      gap: 3px;
      align-items: center;
      position: relative;
      flex-shrink: 0;

      svg {
        width: 16px;
      }

      .waiting-time {
        font-size: 32px;
        line-height: 36px;
        font-family: Roboto;
      }

      .waiting-label {
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        font-size: 12px;
      }
    }

  }

  .waiting-from-section {
    position: relative;
    display: flex;
    align-items: center;
  }

  .waiting-from-label {
    position: absolute;
    bottom: 100%;
    left: 0%;
    white-space: nowrap;
    font-size: 14px;
  }

  .waiting-item {
    display: flex;
    gap: 8px;
    align-items: center;
    width: 100%;
    font-size: 16px;

    .waiting-timestamp {
      font-size: 14px;
      color: #868686;
    }
  }

  .waiting-to-section {
    display: flex;
    align-items: center;
    flex: 1;
  }
// }

.record-section-wrapper {
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 16px;

  .record-section-title {
    font-size: 14px;
    font-weight: 600;
  }

  .record-item-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    .record-table-type {
      font-size: 14px;
    }

    .record-counter-name {
      font-size: 16px;
    }

    .record-timestamp {
      font-size: 14px;
      color: #868686;
    }

    .record-icon.A {
      color: $tableTypeAColor;
    }

    .record-icon.B {
      color: $tableTypeBColor;
    }

    .record-icon.C {
      color: $tableTypeCColor;
    }
    
    .record-icon.D {
      color: $tableTypeDColor;
    }

    .record-icon.E {
      color: $tableTypeEColor;
    }

    .record-icon.F {
      color: $tableTypeFColor;
    }

  }
}

@keyframes call-counter-animation {
  0% {
    background-color: #4CAF50;
  }

  100% {
    background-color: #005D00;
  }
}
@-webkit-keyframes call-counter-animation {
  0% {
    background-color: #4CAF50;
  }
  
  100% {
    background-color: #005D00;
  }
}

@keyframes arrow-animation {
  0% {
    -webkit-mask-position: 200% 0%;
            mask-position: 200% 0%;
  }
  100% {
    -webkit-mask-position: 0% 0%;
            mask-position: 0% 0%;
  }

}
@-webkit-keyframes arrow-animation {
  0% {
    -webkit-mask-position: 200% 0%;
            mask-position: 200% 0%;
  }
  100% {
    -webkit-mask-position: 0% 0%;
            mask-position: 0% 0%;
  }
}

@media only screen and (min-width: $desktopMinWidth) {

  .transferable-ticket-detail-wrapper {
    margin: 2px auto 9px auto;
    width: 90%;

    .middle-line {
      width: 100%;
    }
  }

  .calling-section-wrapper { 

    .calling-item-wrapper {

      .calling-item-detail {

        .calling-item-counter {
  
          font-size: 32px;
      
        }
      
        .calling-item-time-wrapper {
      
          font-size: 24px;
      
        }
  
      }

    }

  }

  .waiting-section-wrapper {

    padding: 32px 0px 16px 0px;

    .waiting-section-title {
      font-size: 24px;
    }
  
    .waiting-item {
      font-size: 24px;
      padding-top: 40px;

      .waiting-timestamp {
        font-size: 24px;
      }
    }

    .solid-arrow {

      // .arrow-tail {
      //   background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.7399334733893557) 8%, rgba(255,255,255,1) 14%, rgba(255,255,255,0.9920343137254902) 68%, rgba(255,255,255,0.5942752100840336) 96%, rgba(255,255,255,0) 100%);
      //   background-size: 200% 100%;
      //   position: relative;
      //   overflow: hidden;
      //   animation: arrow-animation 2.5s linear infinite;
      // }

    }

  }

  .record-section-wrapper {

    padding: 16px 0;

    .record-section-title {
      font-size: 24px;
    }

    .record-item-wrapper {

      .record-table-type {
        font-size: 24px;
      }

      .record-counter-name {
        font-size: 26px;
      }
  
      .record-timestamp {
        font-size: 24px;
      }

    }

  }

}